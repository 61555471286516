.thumbSliderWrapper {
  width: 60%;
  margin: 0 auto;
}
.thumbSliderWrapper .tThumbSlider {
  margin: 30px auto;
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100px;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}
.thumbSliderWrapper .tThumbSlider img {
  height: 76px;
  width: 76px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
.tThumbSlider.active {
  transition: all 0.3s ease-in-out;
  transform: scale(1.5);
}
@media (max-width: 767px) {
  .thumbSliderWrapper .tThumbSlider img {
    height: 56px;
    width: 56px;
  }
}