@import url(https://fonts.googleapis.com/css?family=Karla:400,700|Playfair+Display:400,500,700&display=swap);
.thumbSliderWrapper {
  width: 60%;
  margin: 0 auto;
}
.thumbSliderWrapper .tThumbSlider {
  margin: 30px auto;
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100px;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}
.thumbSliderWrapper .tThumbSlider img {
  height: 76px;
  width: 76px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
.tThumbSlider.active {
  transition: all 0.3s ease-in-out;
  transform: scale(1.5);
}
@media (max-width: 767px) {
  .thumbSliderWrapper .tThumbSlider img {
    height: 56px;
    width: 56px;
  }
}
@font-face {
  font-family: 'custom-icons';
  src: url(/static/media/custom-icons.3b3dc087.eot);
  src: url(/static/media/custom-icons.3b3dc087.eot#iefix) format('embedded-opentype'),
       url(/static/media/custom-icons.257350cc.woff) format('woff'),
       url(/static/media/custom-icons.46ae079e.ttf) format('truetype'),
       url(/static/media/custom-icons.e805f76b.svg#custom-icons) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'custom-icons';
    src: url('../font/custom-icons.svg?46333765#custom-icons') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "custom-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-glyph:before { content: '\e800'; } /* '' */
.icon-glyph-1:before { content: '\e801'; } /* '' */
.icon-glyph-2:before { content: '\e802'; } /* '' */
.icon-glyph-3:before { content: '\e803'; } /* '' */
.icon-glyph-4:before { content: '\e804'; } /* '' */
.icon-glyph-5:before { content: '\e805'; } /* '' */
.icon-glyph-6:before { content: '\e806'; } /* '' */
.icon-glyph-7:before { content: '\e807'; } /* '' */
.icon-glyph-8:before { content: '\e808'; } /* '' */
.icon-glyph-9:before { content: '\e809'; } /* '' */
.icon-glyph-10:before { content: '\e80a'; } /* '' */
.icon-glyph-11:before { content: '\e80b'; } /* '' */
.icon-glyph-12:before { content: '\e80c'; } /* '' */
.icon-glyph-13:before { content: '\e80d'; } /* '' */
.icon-glyph-14:before { content: '\e80e'; } /* '' */
.icon-glyph-15:before { content: '\e80f'; } /* '' */
.icon-glyph-16:before { content: '\e810'; } /* '' */
.icon-glyph-17:before { content: '\e811'; } /* '' */
.icon-glyph-18:before { content: '\e812'; } /* '' */
.icon-glyph-19:before { content: '\e813'; } /* '' */
.icon-glyph-20:before { content: '\e814'; } /* '' */
.icon-glyph-21:before { content: '\e815'; } /* '' */
.icon-glyph-22:before { content: '\e816'; } /* '' */
.icon-glyph-23:before { content: '\e817'; } /* '' */
.icon-glyph-24:before { content: '\e818'; } /* '' */
.icon-glyph-25:before { content: '\e819'; } /* '' */
.icon-glyph-26:before { content: '\e81a'; } /* '' */
.icon-glyph-27:before { content: '\e81b'; } /* '' */
.icon-glyph-28:before { content: '\e81c'; } /* '' */
.icon-glyph-29:before { content: '\e81d'; } /* '' */
.icon-glyph-30:before { content: '\e81e'; } /* '' */
.icon-glyph-31:before { content: '\e81f'; } /* '' */
.icon-glyph-32:before { content: '\e820'; } /* '' */
.icon-glyph-33:before { content: '\e821'; } /* '' */
.icon-glyph-34:before { content: '\e822'; } /* '' */
.icon-glyph-35:before { content: '\e823'; } /* '' */
.icon-glyph-36:before { content: '\e824'; } /* '' */
.icon-glyph-37:before { content: '\e825'; } /* '' */
.icon-glyph-38:before { content: '\e826'; } /* '' */
.icon-glyph-39:before { content: '\e827'; } /* '' */
.icon-glyph-40:before { content: '\e828'; } /* '' */
.icon-glyph-41:before { content: '\e829'; } /* '' */
.icon-glyph-42:before { content: '\e82a'; } /* '' */
.icon-glyph-43:before { content: '\e82b'; } /* '' */
.icon-glyph-44:before { content: '\e82c'; } /* '' */
.icon-glyph-45:before { content: '\e82d'; } /* '' */
.icon-glyph-46:before { content: '\e82e'; } /* '' */
.icon-glyph-47:before { content: '\e82f'; } /* '' */
.icon-glyph-48:before { content: '\e830'; } /* '' */
.icon-glyph-49:before { content: '\e831'; } /* '' */
.icon-glyph-50:before { content: '\e832'; } /* '' */
.icon-glyph-51:before { content: '\e833'; } /* '' */
.icon-glyph-52:before { content: '\e834'; } /* '' */
.icon-glyph-53:before { content: '\e835'; } /* '' */
.icon-glyph-54:before { content: '\e836'; } /* '' */
.icon-glyph-55:before { content: '\e837'; } /* '' */
.icon-glyph-56:before { content: '\e838'; } /* '' */
.icon-glyph-57:before { content: '\e839'; } /* '' */
.icon-glyph-58:before { content: '\e83a'; } /* '' */
.icon-glyph-59:before { content: '\e83b'; } /* '' */
.icon-glyph-60:before { content: '\e83c'; } /* '' */
.icon-glyph-61:before { content: '\e83d'; } /* '' */
.icon-glyph-62:before { content: '\e83e'; } /* '' */
.icon-glyph-63:before { content: '\e83f'; } /* '' */
.icon-glyph-64:before { content: '\e840'; } /* '' */
.icon-glyph-65:before { content: '\e841'; } /* '' */
.icon-glyph-66:before { content: '\e842'; } /* '' */
.icon-glyph-67:before { content: '\e843'; } /* '' */
.icon-glyph-68:before { content: '\e844'; } /* '' */
.icon-glyph-69:before { content: '\e845'; } /* '' */
.icon-glyph-70:before { content: '\e846'; } /* '' */
.icon-glyph-71:before { content: '\e847'; } /* '' */
.icon-glyph-72:before { content: '\e848'; } /* '' */
.icon-glyph-73:before { content: '\e849'; } /* '' */
.icon-glyph-74:before { content: '\e84a'; } /* '' */
.icon-glyph-75:before { content: '\e84b'; } /* '' */
.icon-glyph-76:before { content: '\e84c'; } /* '' */
.icon-glyph-77:before { content: '\e84d'; } /* '' */
.icon-glyph-78:before { content: '\e84e'; } /* '' */
.icon-glyph-79:before { content: '\e84f'; } /* '' */
.icon-glyph-80:before { content: '\e850'; } /* '' */
.icon-glyph-81:before { content: '\e851'; } /* '' */
.icon-glyph-82:before { content: '\e852'; } /* '' */
.icon-glyph-83:before { content: '\e853'; } /* '' */
.icon-glyph-84:before { content: '\e854'; } /* '' */
.icon-glyph-85:before { content: '\e855'; } /* '' */
.icon-glyph-86:before { content: '\e856'; } /* '' */
.icon-glyph-87:before { content: '\e857'; } /* '' */
.icon-glyph-88:before { content: '\e858'; } /* '' */
.icon-glyph-89:before { content: '\e859'; } /* '' */
.icon-glyph-90:before { content: '\e85a'; } /* '' */
.icon-glyph-91:before { content: '\e85b'; } /* '' */
.icon-glyph-92:before { content: '\e85c'; } /* '' */
.icon-glyph-93:before { content: '\e85d'; } /* '' */
.icon-glyph-94:before { content: '\e85e'; } /* '' */
.icon-glyph-95:before { content: '\e85f'; } /* '' */
.icon-glyph-96:before { content: '\e860'; } /* '' */
.icon-glyph-97:before { content: '\e861'; } /* '' */
.icon-glyph-98:before { content: '\e862'; } /* '' */
.icon-glyph-99:before { content: '\e863'; } /* '' */
.icon-glyph-100:before { content: '\e864'; } /* '' */
.icon-glyph-101:before { content: '\e865'; } /* '' */
.icon-glyph-102:before { content: '\e866'; } /* '' */
.icon-glyph-103:before { content: '\e867'; } /* '' */
.icon-glyph-104:before { content: '\e868'; } /* '' */
.icon-glyph-105:before { content: '\e869'; } /* '' */
.icon-glyph-106:before { content: '\e86a'; } /* '' */
.icon-glyph-107:before { content: '\e86b'; } /* '' */
.icon-glyph-108:before { content: '\e86c'; } /* '' */
.icon-glyph-109:before { content: '\e86d'; } /* '' */
.icon-glyph-110:before { content: '\e86e'; } /* '' */
.icon-glyph-111:before { content: '\e86f'; } /* '' */
.icon-glyph-112:before { content: '\e870'; } /* '' */
.icon-glyph-113:before { content: '\e871'; } /* '' */
.icon-glyph-114:before { content: '\e872'; } /* '' */
.icon-glyph-115:before { content: '\e873'; } /* '' */
.icon-glyph-116:before { content: '\e874'; } /* '' */
.icon-glyph-117:before { content: '\e875'; } /* '' */
.icon-glyph-118:before { content: '\e876'; } /* '' */
.icon-glyph-119:before { content: '\e877'; } /* '' */
.icon-glyph-120:before { content: '\e878'; } /* '' */
.icon-glyph-121:before { content: '\e879'; } /* '' */
.icon-glyph-122:before { content: '\e87a'; } /* '' */
.icon-glyph-123:before { content: '\e87b'; } /* '' */
.icon-glyph-124:before { content: '\e87c'; } /* '' */
.icon-glyph-125:before { content: '\e87d'; } /* '' */
.icon-glyph-126:before { content: '\e87e'; } /* '' */
.icon-glyph-127:before { content: '\e87f'; } /* '' */
.icon-glyph-128:before { content: '\e880'; } /* '' */
.icon-glyph-129:before { content: '\e881'; } /* '' */
.icon-glyph-130:before { content: '\e882'; } /* '' */
.icon-glyph-131:before { content: '\e883'; } /* '' */
.icon-glyph-132:before { content: '\e884'; } /* '' */
.icon-glyph-133:before { content: '\e885'; } /* '' */
.icon-glyph-134:before { content: '\e886'; } /* '' */
.icon-glyph-135:before { content: '\e887'; } /* '' */
.icon-glyph-136:before { content: '\e888'; } /* '' */
.icon-glyph-137:before { content: '\e889'; } /* '' */
.icon-glyph-138:before { content: '\e88a'; } /* '' */
.icon-glyph-139:before { content: '\e88b'; } /* '' */
.icon-glyph-140:before { content: '\e88c'; } /* '' */
.icon-glyph-141:before { content: '\e88d'; } /* '' */
.icon-glyph-142:before { content: '\e88e'; } /* '' */
.icon-glyph-143:before { content: '\e88f'; } /* '' */
.icon-glyph-144:before { content: '\e890'; } /* '' */
.icon-glyph-145:before { content: '\e891'; } /* '' */
.icon-glyph-146:before { content: '\e892'; } /* '' */
.icon-glyph-147:before { content: '\e893'; } /* '' */
.icon-glyph-148:before { content: '\e894'; } /* '' */
.icon-glyph-149:before { content: '\e895'; } /* '' */
.icon-glyph-150:before { content: '\e896'; } /* '' */
.icon-glyph-151:before { content: '\e897'; } /* '' */
.icon-glyph-152:before { content: '\e898'; } /* '' */
.icon-glyph-153:before { content: '\e899'; } /* '' */
.icon-glyph-154:before { content: '\e89a'; } /* '' */
.icon-glyph-155:before { content: '\e89b'; } /* '' */
.icon-glyph-156:before { content: '\e89c'; } /* '' */
.icon-glyph-157:before { content: '\e89d'; } /* '' */
.icon-glyph-158:before { content: '\e89e'; } /* '' */
.icon-glyph-159:before { content: '\e89f'; } /* '' */
.icon-glyph-160:before { content: '\e8a0'; } /* '' */
.icon-glyph-161:before { content: '\e8a1'; } /* '' */
.icon-glyph-162:before { content: '\e8a2'; } /* '' */
.icon-glyph-163:before { content: '\e8a3'; } /* '' */
.icon-glyph-164:before { content: '\e8a4'; } /* '' */
.icon-glyph-165:before { content: '\e8a5'; } /* '' */
.icon-glyph-166:before { content: '\e8a6'; } /* '' */
.icon-glyph-167:before { content: '\e8a7'; } /* '' */
.icon-glyph-168:before { content: '\e8a8'; } /* '' */
.icon-glyph-169:before { content: '\e8a9'; } /* '' */
.icon-glyph-170:before { content: '\e8aa'; } /* '' */
.icon-glyph-171:before { content: '\e8ab'; } /* '' */
.icon-glyph-172:before { content: '\e8ac'; } /* '' */
.icon-glyph-173:before { content: '\e8ad'; } /* '' */
.icon-glyph-174:before { content: '\e8ae'; } /* '' */
.icon-glyph-175:before { content: '\e8af'; } /* '' */
.icon-glyph-176:before { content: '\e8b0'; } /* '' */
.icon-glyph-177:before { content: '\e8b1'; } /* '' */
.icon-glyph-178:before { content: '\e8b2'; } /* '' */
.icon-glyph-179:before { content: '\e8b3'; } /* '' */
.icon-glyph-180:before { content: '\e8b4'; } /* '' */
.icon-glyph-181:before { content: '\e8b5'; } /* '' */
.icon-glyph-182:before { content: '\e8b6'; } /* '' */
.icon-glyph-183:before { content: '\e8b7'; } /* '' */
.icon-glyph-184:before { content: '\e8b8'; } /* '' */
.icon-glyph-185:before { content: '\e8b9'; } /* '' */
.icon-glyph-186:before { content: '\e8ba'; } /* '' */
.icon-glyph-187:before { content: '\e8bb'; } /* '' */
.icon-glyph-188:before { content: '\e8bc'; } /* '' */
.icon-glyph-189:before { content: '\e8bd'; } /* '' */
.icon-glyph-190:before { content: '\e8be'; } /* '' */
.icon-glyph-191:before { content: '\e8bf'; } /* '' */
.icon-glyph-192:before { content: '\e8c0'; } /* '' */
.icon-glyph-193:before { content: '\e8c1'; } /* '' */
.icon-glyph-194:before { content: '\e8c2'; } /* '' */
.icon-glyph-195:before { content: '\e8c3'; } /* '' */
.icon-glyph-196:before { content: '\e8c4'; } /* '' */
.icon-glyph-197:before { content: '\e8c5'; } /* '' */
.icon-glyph-198:before { content: '\e8c6'; } /* '' */
.icon-glyph-199:before { content: '\e8c7'; } /* '' */
.icon-glyph-200:before { content: '\e8c8'; } /* '' */
.icon-glyph-201:before { content: '\e8c9'; } /* '' */
.icon-glyph-202:before { content: '\e8ca'; } /* '' */
.icon-glyph-203:before { content: '\e8cb'; } /* '' */
.icon-glyph-204:before { content: '\e8cc'; } /* '' */
.icon-glyph-205:before { content: '\e8cd'; } /* '' */
.icon-glyph-206:before { content: '\e8ce'; } /* '' */
.icon-glyph-207:before { content: '\e8cf'; } /* '' */
.icon-glyph-208:before { content: '\e8d0'; } /* '' */
.icon-glyph-209:before { content: '\e8d1'; } /* '' */
.icon-glyph-210:before { content: '\e8d2'; } /* '' */
.icon-glyph-211:before { content: '\e8d3'; } /* '' */
.icon-glyph-212:before { content: '\e8d4'; } /* '' */
.icon-glyph-213:before { content: '\e8d5'; } /* '' */
.icon-glyph-214:before { content: '\e8d6'; } /* '' */
.icon-glyph-215:before { content: '\e8d7'; } /* '' */
.icon-glyph-216:before { content: '\e8d8'; } /* '' */
.icon-glyph-217:before { content: '\e8d9'; } /* '' */
.icon-glyph-218:before { content: '\e8da'; } /* '' */
.icon-glyph-219:before { content: '\e8db'; } /* '' */
.icon-glyph-220:before { content: '\e8dc'; } /* '' */
.icon-glyph-221:before { content: '\e8dd'; } /* '' */
.icon-glyph-222:before { content: '\e8de'; } /* '' */
.icon-glyph-223:before { content: '\e8df'; } /* '' */
.icon-glyph-224:before { content: '\e8e0'; } /* '' */
.icon-glyph-225:before { content: '\e8e1'; } /* '' */
.icon-glyph-226:before { content: '\e8e2'; } /* '' */
.icon-glyph-227:before { content: '\e8e3'; } /* '' */
.icon-glyph-228:before { content: '\e8e4'; } /* '' */
.icon-glyph-229:before { content: '\e8e5'; } /* '' */
.icon-glyph-230:before { content: '\e8e6'; } /* '' */
.icon-glyph-231:before { content: '\e8e7'; } /* '' */
.icon-glyph-232:before { content: '\e8e8'; } /* '' */
.icon-glyph-233:before { content: '\e8e9'; } /* '' */
.icon-glyph-234:before { content: '\e8ea'; } /* '' */
.icon-glyph-235:before { content: '\e8eb'; } /* '' */
.icon-glyph-236:before { content: '\e8ec'; } /* '' */
.icon-glyph-237:before { content: '\e8ed'; } /* '' */
.icon-glyph-238:before { content: '\e8ee'; } /* '' */
.icon-glyph-239:before { content: '\e8ef'; } /* '' */
.icon-glyph-240:before { content: '\e8f0'; } /* '' */
.icon-glyph-241:before { content: '\e8f1'; } /* '' */
.icon-glyph-242:before { content: '\e8f2'; } /* '' */
.icon-glyph-243:before { content: '\e8f3'; } /* '' */
.icon-glyph-244:before { content: '\e8f4'; } /* '' */
.icon-glyph-245:before { content: '\e8f5'; } /* '' */
.icon-glyph-246:before { content: '\e8f6'; } /* '' */
.icon-glyph-247:before { content: '\e8f7'; } /* '' */
.icon-glyph-248:before { content: '\e8f8'; } /* '' */
.icon-glyph-249:before { content: '\e8f9'; } /* '' */
.icon-glyph-250:before { content: '\e8fa'; } /* '' */
.icon-glyph-251:before { content: '\e8fb'; } /* '' */
.icon-glyph-252:before { content: '\e8fc'; } /* '' */
.icon-glyph-253:before { content: '\e8fd'; } /* '' */
.icon-glyph-254:before { content: '\e8fe'; } /* '' */
.icon-glyph-255:before { content: '\e8ff'; } /* '' */
.icon-glyph-256:before { content: '\e900'; } /* '' */
.icon-glyph-257:before { content: '\e901'; } /* '' */
.icon-glyph-258:before { content: '\e902'; } /* '' */
.icon-glyph-259:before { content: '\e903'; } /* '' */
.icon-glyph-260:before { content: '\e904'; } /* '' */
.icon-glyph-261:before { content: '\e905'; } /* '' */
.icon-glyph-262:before { content: '\e906'; } /* '' */
.icon-glyph-263:before { content: '\e907'; } /* '' */
.icon-glyph-264:before { content: '\e908'; } /* '' */
.icon-glyph-265:before { content: '\e909'; } /* '' */
.icon-glyph-266:before { content: '\e90a'; } /* '' */
.icon-glyph-267:before { content: '\e90b'; } /* '' */
.icon-glyph-268:before { content: '\e90c'; } /* '' */
.icon-glyph-269:before { content: '\e90d'; } /* '' */
.icon-glyph-270:before { content: '\e90e'; } /* '' */
.icon-glyph-271:before { content: '\e90f'; } /* '' */
.icon-glyph-272:before { content: '\e910'; } /* '' */
.icon-glyph-273:before { content: '\e911'; } /* '' */
.icon-glyph-274:before { content: '\e912'; } /* '' */
.icon-glyph-275:before { content: '\e913'; } /* '' */
.icon-glyph-276:before { content: '\e914'; } /* '' */
.icon-glyph-277:before { content: '\e915'; } /* '' */
.icon-glyph-278:before { content: '\e916'; } /* '' */
.icon-glyph-279:before { content: '\e917'; } /* '' */
.icon-glyph-280:before { content: '\e918'; } /* '' */
.icon-glyph-281:before { content: '\e919'; } /* '' */
.icon-glyph-282:before { content: '\e91a'; } /* '' */
.icon-glyph-283:before { content: '\e91b'; } /* '' */
.icon-glyph-284:before { content: '\e91c'; } /* '' */
.icon-glyph-285:before { content: '\e91d'; } /* '' */
.icon-glyph-286:before { content: '\e91e'; } /* '' */
.icon-glyph-287:before { content: '\e91f'; } /* '' */
.icon-glyph-288:before { content: '\e920'; } /* '' */
.icon-glyph-289:before { content: '\e921'; } /* '' */
.icon-glyph-290:before { content: '\e922'; } /* '' */
.icon-glyph-291:before { content: '\e923'; } /* '' */
.icon-glyph-292:before { content: '\e924'; } /* '' */
.icon-glyph-293:before { content: '\e925'; } /* '' */
.icon-glyph-294:before { content: '\e926'; } /* '' */
.icon-glyph-295:before { content: '\e927'; } /* '' */
.icon-glyph-296:before { content: '\e928'; } /* '' */
.icon-glyph-297:before { content: '\e929'; } /* '' */
.icon-glyph-298:before { content: '\e92a'; } /* '' */
.icon-glyph-299:before { content: '\e92b'; } /* '' */
.icon-glyph-300:before { content: '\e92c'; } /* '' */
.icon-glyph-301:before { content: '\e92d'; } /* '' */
.icon-glyph-302:before { content: '\e92e'; } /* '' */
.icon-glyph-303:before { content: '\e92f'; } /* '' */
.icon-glyph-304:before { content: '\e930'; } /* '' */
.icon-glyph-305:before { content: '\e931'; } /* '' */
.icon-glyph-306:before { content: '\e932'; } /* '' */
.icon-glyph-307:before { content: '\e933'; } /* '' */
.icon-glyph-308:before { content: '\e934'; } /* '' */
.icon-glyph-309:before { content: '\e935'; } /* '' */
.icon-glyph-310:before { content: '\e936'; } /* '' */
.icon-glyph-311:before { content: '\e937'; } /* '' */
.icon-glyph-312:before { content: '\e938'; } /* '' */
.icon-glyph-313:before { content: '\e939'; } /* '' */
.icon-glyph-314:before { content: '\e93a'; } /* '' */
.icon-glyph-315:before { content: '\e93b'; } /* '' */
.icon-glyph-316:before { content: '\e93c'; } /* '' */
.icon-glyph-317:before { content: '\e93d'; } /* '' */
.icon-glyph-318:before { content: '\e93e'; } /* '' */
.icon-glyph-319:before { content: '\e93f'; } /* '' */
.icon-glyph-320:before { content: '\e940'; } /* '' */
.icon-glyph-321:before { content: '\e941'; } /* '' */
.icon-glyph-322:before { content: '\e942'; } /* '' */
.icon-glyph-323:before { content: '\e943'; } /* '' */
.icon-glyph-324:before { content: '\e944'; } /* '' */
.icon-glyph-325:before { content: '\e945'; } /* '' */
.icon-glyph-326:before { content: '\e946'; } /* '' */
.icon-glyph-327:before { content: '\e947'; } /* '' */
.icon-glyph-328:before { content: '\e948'; } /* '' */
.icon-glyph-329:before { content: '\e949'; } /* '' */
.icon-glyph-330:before { content: '\e94a'; } /* '' */
.icon-glyph-331:before { content: '\e94b'; } /* '' */
.icon-glyph-332:before { content: '\e94c'; } /* '' */
.icon-glyph-333:before { content: '\e94d'; } /* '' */
.icon-glyph-334:before { content: '\e94e'; } /* '' */
.icon-glyph-335:before { content: '\e94f'; } /* '' */
.icon-glyph-336:before { content: '\e950'; } /* '' */
.icon-glyph-337:before { content: '\e951'; } /* '' */
.icon-glyph-338:before { content: '\e952'; } /* '' */
.icon-glyph-339:before { content: '\e953'; } /* '' */
.icon-glyph-340:before { content: '\e954'; } /* '' */
.icon-glyph-341:before { content: '\e955'; } /* '' */
.icon-glyph-342:before { content: '\e956'; } /* '' */
.icon-glyph-343:before { content: '\e957'; } /* '' */
.icon-glyph-344:before { content: '\e958'; } /* '' */
.icon-glyph-345:before { content: '\e959'; } /* '' */
.icon-glyph-346:before { content: '\e95a'; } /* '' */
.icon-glyph-347:before { content: '\e95b'; } /* '' */
.icon-glyph-348:before { content: '\e95c'; } /* '' */
.icon-glyph-349:before { content: '\e95d'; } /* '' */
.icon-glyph-350:before { content: '\e95e'; } /* '' */
.icon-down-open:before { content: '\e95f'; } /* '' */
.icon-up-open:before { content: '\e960'; } /* '' */
.icon-left-open:before { content: '\e961'; } /* '' */
.icon-right-open:before { content: '\e962'; } /* '' */
/*------------------------------------------------------------------
Project: Dhaam Organic - Organic Food HTML Template
Version: 1.1
Primary use: Food Farms, Organic Farms 
-------------------------------------------------------------------*/

/* Index of CSS
==================================================
1. Global styles

2. Header Section

3. Service Section /.service-section

4. About Section /.about_process

5. Pricing Section /.pricing-section

6. Call To Action Section /.calltoaction

7. Index Section /.work-section

8. Video Section /.video-section

9. Team Section /.team-section

10. Blog Section /.blog-section

11. Contact Section /.contact-section

12. Footer Section /.footer-section

[===== Single Page ===== ]

13. Blog Page / .blog_container
  
14. Sigle Blog Page / .single_blog_container

15. About page / .about_page

16. Index page / .work_section_page

17. Contact page / .contact_page

18. Testimonial Page /.testimonial-section

*/

/*----------------------------------------------------
      1. Global styles
  ------------------------------------------------------*/

.cursor-pointer {
  cursor: pointer;
}
body {
  background: #fff;
  color: #61657a;
  font-family: 'Karla', sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 26px;
  margin: 0;
  padding: 0;
  position: relative;
}

img {
  max-width: 100%;
}
html {
  scroll-behavior: smooth;
}

/* === PARAGRAPH === */

p {
  margin: 0 0 10px;
  text-align: justify;
}

b,
strong {
  font-weight: 600;
}

/* ===  Headings === */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2b343b;
  font-family: 'Playfair Display', sans-serif;
  margin: 0 0 15px;
  padding: 0;
  transition: 0.3s;
}

h1 {
  font-size: 36px;
  line-height: 52px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
}

h3 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

h4 {
  font-size: 22px;
  line-height: 30px;
}

h5 {
  font-size: 20px;
  line-height: 28px;
}

h6 {
  font-size: 18px;
}

/* ## End: Headings ## */

/* === Hyperlink === */

a {
  color: #303030;
  /*transition*/
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

a,
a > * {
  cursor: pointer;
  outline: medium none;
  text-decoration: none;
}

a:focus,
a:hover {
  outline: medium none;
  text-decoration: none;
  color: inherit;
  text-decoration: none !important;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* === Hyperlink === */

.btn.btn-fill {
  background: #fff;
}

.btn.btn-fill {
  color: #000;
}

.btn {
  border-width: 1px;
  border-style: solid;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 22px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  transition: all 0.4s ease-out 0s;
  color: #fff;
}

section {
  background: #fff;
  padding-top: 15px;
  padding-bottom: 90px;
}

.base-header {
  padding-bottom: 40px;
  width: 57%;
  margin: auto;
  text-align: center;
}

.base-header small {
  font-size: 18px;
  color: #4ba755;
  text-transform: capitalize;
}

.base-header h3 {
  padding-bottom: 16px;
  position: relative;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0px;
  letter-spacing: 0.5px;
  font-size: 40px;
  line-height: 50px;
  color: #2b343b;
  margin-top: 5px;
}

.base-header h3::before {
  position: absolute;
  content: '';
  background-color: #aedf04;
}

.base-header h3::before,
.base-header h3::after {
  position: absolute;
  content: '';
  background-color: #4ba75596;
  width: 15px;
  height: 11px;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
  border-radius: 136px;
  margin-bottom: -6px;
}

.base-header h3::after {
  margin-left: -12px;
  background-color: #4ba755;
}

.base-header.base_header_left {
  width: 100%;
  text-align: left;
}

.base-header.base_header_left h3::after,
.base-header.base_header_left h3::before {
  left: 0%;
  margin-left: 0px;
}

.base-header.base_header_left h3::before {
  margin-left: 10px;
}

.more-link:before {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #2b343b;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
}

.more-link:hover::before {
  height: 400%;
  opacity: 1;
}

.more-link:hover {
  color: #fff;
}

.more-link {
  display: inline-block;
  font-size: 16px;
  padding: 12px 35px 13px 35px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
}

.more-link {
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  transition-property: color;
  transition-duration: 0.3s;
  /*background: #4BA755;*/
  background: #4ba755;
  border-radius: 2px;
}

.owl-prev,
.owl-next {
  transition: all 0.4s linear 0s;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 99;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #333;
  opacity: 0;
}

.owl-theme .owl-controls .owl-nav div {
  padding-right: 20px;
}

.owl-theme .owl-controls .owl-nav .owl-prev {
  left: 0px;
  transition: all 0.3s ease-out;
}

.owl-theme .owl-controls .owl-nav .owl-next {
  right: 15px;
  transition: all 0.3s ease-out;
}

.owl-nav {
  bottom: -50px;
  left: 0%;
}

.owl-prev {
  margin-left: 0;
}

.owl-nav div.owl-next {
  left: 60px;
  right: 20px;
}

.owl-nav div {
  color: #333;
  font-size: 19px;
  height: 50px;
  left: 0px;
  line-height: 36px;
  margin-top: -30px;
  opacity: 0.8;
  position: absolute;
  text-align: center;
  top: 50.5%;
  width: 50px;
  border-radius: 50%;
  padding-right: 0px !important;
  padding-top: 5px;
}

/*----------------------------------------------------
     2. Header Section
  ------------------------------------------------------*/
/*----- Top bar -----*/

.header_topbar {
  background: #2b343b;
}

.header_topbar .container {
  display: flex;
}

.top-bar .header_top_left {
  padding: 0 0;
}

.header_socil {
  margin: 0 0 0 10px;
  float: right;
}

.header_socil li {
  padding-left: 1px;
  padding-right: 1px;
}

.header_socil li {
  font-size: 14px;
  margin: 0;
  position: relative;
  display: inline-block;
  text-align: center;
}

.header_socil li .fa {
  color: #fff;
  font-size: 14px;
  margin-left: 8px;
}

.header_socil li .fa:hover {
  color: #4ba755;
}

.header_socil li:nth-child(1) i {
  margin-right: 8px;
  color: #4ba755;
}

.header_socil li:nth-child(1) {
  border-right: 1px solid #645454;
  padding-right: 20px;
  color: #fff;
  margin-right: 10px;
}

.header_topbar .more-link {
  margin-left: 20px;
  border-radius: 0px;
}

.header_top_left {
  padding-left: 1px;
  float: left;
  display: flex;
}

.header_top_right {
  margin-right: auto;
}

.header_topbar ul {
  margin-top: 8px;
  margin-bottom: 8px;
}

.header_top_right ul {
  margin-right: 17px;
}

.header_top_right li {
  display: inline;
  padding-right: 20px;
  color: #fff;
  font-size: 14px;
}

.header_top_right li i {
  margin-right: 10px;
  color: #4ba755;
  font-size: 15px;
  position: relative;
  top: 1px;
}
/*----- End : Top bar -----*/
.menu_area {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 9;
  box-shadow: 0 3px 6px rgba(54, 54, 54, 0.01);
}
.menu_area .container {
  display: flex;
}
.navigation .logo {
  float: left;
  position: relative;
  height: 73px;
  margin-right: auto;
}

.navigation .logo img {
  margin-top: 26px;
  max-height: 50px;
}

.logo img {
  max-width: inherit;
}

.navigation .logo::before {
  background: transparent !important;
}

.navigation .logo::before {
  position: absolute;
  top: 0px;
  width: 243%;
  content: '';
  height: 100%;
  left: -240%;
}

.navigation .logo::after {
  background: transparent !important;
}

.navigation .logo::after {
  width: 121px;
  height: 73px;
  position: absolute;
  right: -121px;
  top: 0px;
  content: '';
}

.logo {
  background: transparent !important;
  width: 189px;
}

/*-----Navigationn-----*/

.navigation {
  background-color: #fff;
  z-index: 7;
  position: relative;
  border-top: 1px solid #fbf3f3;
}

#navigation {
  float: right;
  margin-left: -18px !important;
}

#navigation {
  float: right;
  margin-right: -58px !important;
}

.is-sticky .navigation {
  box-shadow: 0 3px 6px rgba(54, 54, 54, 0.08);
}

#navigation,
#navigation ul,
#navigation ul li,
#navigation ul li a,
#navigation #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 14px;
  display: block;
  position: relative;
  box-sizing: border-box;
}
#navigation ul li {
  display: inline-block;
}

#navigation:after,
#navigation > ul:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

#navigation #menu-button {
  display: none;
}

#navigation > ul > li {
  float: left;
  margin-right: 5px;
}

#navigation li:hover > ul {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;
}

#navigation.align-center > ul {
  font-size: 0;
  text-align: center;
}

#navigation.align-center > ul > li {
  display: inline-block;
  float: none;
}

#navigation.align-center ul ul {
  text-align: left;
}

#navigation.align-right > ul > li {
  float: right;
}
#navigation ul li a:hover {
  color: #4ba755;
}
#navigation ul li a {
  padding: 42px 0px 42px 32px;
  font-size: 15px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #333;
  text-transform: uppercase;
  margin-right: -1px;
  cursor: pointer;
}

#navigation > ul > li.has-sub > a {
  padding: 42px 0px 42px 32px;
}

#navigation > ul > li.has-sub > a::before {
  position: absolute;
  right: 18px;
  font-family: FontAwesome;
  content: '\f107';
  transition: all 0.25s ease;
}

#navigation > ul > li.has-sub > a::before {
  position: absolute;
  right: 14px;
  font-family: FontAwesome;
  content: '\f107';
  transition: all 0.25s ease;
  display: none;
}

#navigation ul ul li.has-sub > a::before {
  position: absolute;
  top: 20px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #4ba755;
  content: '';
  transition: all 0.25s ease;
}

#navigation ul ul li.has-sub > a::after {
  position: absolute;
  top: 23px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #4ba755;
  content: '';
}

#navigation ul ul li.has-sub:hover a::before {
  top: 17px;
  height: 0;
}

#navigation ul ul {
  position: absolute;
  left: -9999px;
}

#navigation.align-right ul ul {
  text-align: right;
}

#navigation ul ul li {
  height: 0;
  transition: all 0.25s ease;
}

#navigation li:hover > ul {
  left: auto;
}

#navigation.align-right li:hover > ul {
  left: auto;
  right: 0;
}

#navigation li:hover > ul > li {
  height: 40px;
}

#navigation ul ul ul {
  margin-left: 100%;
  top: 0;
}

#navigation.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}

#navigation ul ul li a {
  border-bottom: 1px solid #4ba755;
  padding: 14px 10px 14px 20px;
  width: 220px;
  font-size: 15px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  background: #fff;
  text-transform: uppercase;
  margin-left: 20px;
  border-left: 1px solid #f1f1f1;
  line-height: 13px;
}

#navigation ul ul li:last-child > a,
#navigation ul ul li.last-item > a {
  border-bottom: 0;
}

#navigation ul ul li:hover a,
#navigation ul ul li a:hover {
  color: #4ba755;
  border-left: 1px solid #4ba755;
}

/*-----Navigationn end-----*/

@media (max-width: 991px) {
  .menu_area {
    background: #fff;
    height: 75px;
  }
  .navigation {
    background-color: transparent;
    position: absolute;
    width: 100%;
  }
  .navbar-light .navbar-toggler {
    position: absolute;
    right: 0px;
    top: 22px;
    padding: 2px 8px;
  }
  .navbar-toggler-icon {
    width: 1.1em !important;
    height: 1.1em !important;
  }
  .navbar-collapse {
    position: absolute;
    right: 0px;
    background: #fff;
    top: 74px;
    width: 100%;
    text-align: left;
    padding: 10px 10px;
  }
  #navigation {
    float: none;
    max-width: 720px;
    width: 100%;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  #navigation ul li {
    display: block;
  }
  #navigation ul li a {
    padding: 10px 10px;
  }
  #navigation ul ul {
    z-index: 999;
  }

  .navigation .logo img {
    margin-top: 92px !important;
  }

  .header_cart {
    position: absolute;
    right: 0px;
    margin-right: 52px;
    margin-top: 23px !important;
  }
  .header_cart ul {
    border-left: 1px solid transparent !important;
    border-right: 1px solid #ddd;
    padding-right: 15px !important;
    margin-right: 15px;
  }
  .header_cart ul li {
    margin-left: 10px;
  }
}

/*----- Menu Search and Cart -----*/
.search_icon_inr {
  display: none;
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
  width: 290px;
  z-index: 9999;
  margin-top: 40px;
  transition: 0.5s;
}

.search_icon_inr.active {
  display: block;
  opacity: 1;
  height: auto;
}

.search_icon_inr form {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #898989;
  float: left;
  width: 100%;
}

.search_icon_inr input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #000000;
  float: left;
  font-size: 13px;
  font-weight: 500;
  height: 38px;
  padding: 0 0 0 15px;
  width: 100%;
}

.search_icon_inr .btn-search {
  border: medium none;
  color: #fff;
  height: 40px;
  line-height: 38px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0px;
  transition: all 0.3s ease 0s;
  width: 40px;
  background: #4ba755;
}

.header_cart {
  margin-top: 36px;
  text-align: right;
  margin-left: 68px;
}

.header_cart ul {
  border-left: 1px solid #ddd;
  padding-right: 0px;
  padding-left: 26px;
  margin-left: 22px;
}

.header_cart ul li {
  display: inline;
  margin-left: 6px;
  position: relative;
}

.header_cart li a {
  background: #f2eaea;
  border-radius: 50%;
  padding: 0px 10px;
  font-size: 13px;
  color: #303030;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
}
.header_cart_box {
  background: #4ba755;
  color: #fff;
  padding: 4px 6px;
  border-radius: 5px;
  display: inline-flex !important;
  grid-gap: 10px;
  gap: 10px;
  font-size: 11px;
  transition: 0.3s;
  margin-right: 10px;
}
.price {
  height: 57px;
}
.number_cart {
  /* position: absolute;
  top: -16px;
  background: #4ba755;
  padding: 2px 6px;
  border-radius: 50%;
  color: #fff;
  line-height: 15px;
  right: -2px; */
}

.header_cart li a:hover {
  background: #4ba755;
  color: #fff;
}
/*----- End :Menu Search and Cart -----*/

/*
   Header Transparent CSS Start 
  ----------------------------------------*/

.header-transparent {
  padding-bottom: 20px;
}

.top-bar-transparent {
  margin-bottom: 20px;
}

.header-transparent #navigation {
  margin-top: 5px;
  background-color: transparent;
}

.header-transparent #navigation > ul > li > a {
  padding: 10px 4px;
  border: transparent;
  color: #fff;
}

.header-top {
  border-bottom: 1px solid transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  border-color: rgba(250, 250, 250, 0.2);
}

.header-transparent #navigation li a {
  color: #ffffff;
  background-color: transparent;
  border-left: transparent;
  border-right: transparent;
  padding: 14px 30px 14px 10px;
}

.header-transparent #navigation li ul li a {
  background-color: #446a73;
  padding: 18px 20px;
}

.header-transparent #navigation > ul > li.has-sub > a {
  padding-right: 30px;
}

.header-transparent #navigation li ul li a {
  border-bottom: 1px solid #375b63;
}

.header-top .top-nav ul li a {
  color: #fff;
}

.top-nav-collapse {
  background-color: rgba(18, 45, 52, 0.8);
}

#search-area-v2 {
  position: absolute;
  width: 100%;
  z-index: 1032;
  top: 48px;
}

#search-area-v2 .well-bg {
  background-color: #333;
}

.phone-img {
  margin-bottom: 60px;
}
/*Header Transparent CSS End
  ----------------------------------------*/
/*  Slider Area Styling */

.slide_bg_1 {
  background: #f0f0f0 url('/assets/images/slider1.webp') no-repeat scroll right
    center;
}

.slide_bg_2 {
  background: #f0f0f0 url('/assets/images/slider2.webp') no-repeat scroll right
    center;
}

.slide_bg_3 {
  background: #f0f0f0 url('/assets/images/slider3.webp') no-repeat scroll right
    center;
}

.single_slider {
  background-color: #dddd;
  background-position: center center;
  background-size: cover;
  color: #fff;
  font-size: 24px;
  height: 700px;
}
.single_slider_about{
  background-size: contain !important;
  height: 580px;
}

.single_slider .container {
  margin-top: -6px;
}

.slider_item_tb {
  display: table;
  height: 100%;
  width: 100%;
}

.slider_item_tbcell {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.slide_bg_2 .slider_item_tbcell {
  text-align: right;
}

.single_slider.slide_bg_2 .col-lg-6 {
  margin-left: 49.5%;
}

.slider_home_two .col-md-offset-3 {
  margin-left: 21%;
}

.slide_bg_2 .col-lg-7.col-xs-12 {
  margin-left: 21%;
}

.slider_home h2 {
  font-size: 54px;
  margin-bottom: 25px;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 64px;
  color: #2b343b;
  margin-top: -14px;
}

.slider_home h5 {
  font-size: 20px;
  margin-bottom: 35px;
  font-weight: 300;
  color: #4ba755;
  position: relative;
  padding-left: 30px;
}

.slider_home h5::before {
  position: absolute;
  content: '';
  background-color: #4ba75596;
  width: 15px;
  height: 11px;
  bottom: 13px;
  left: 7px;
  margin-left: -1px;
  border-radius: 136px;
  margin-bottom: -6px;
}

.slider_home h5::after {
  position: absolute;
  content: '';
  background-color: #4ba755;
  width: 15px;
  height: 11px;
  bottom: 13px;
  left: 0px;
  margin-left: -1px;
  border-radius: 136px;
  margin-bottom: -6px;
}

.slide_bg_2 h5 {
  padding-right: 33px;
}

.slide_bg_2 h5::before {
  left: 96%;
}

.slide_bg_2 h5::after {
  left: 98%;
}

.slider_home h2 .slide_st1 {
  color: #2c2c2c;
  font-weight: 700;
}

.slider_home p {
  color: #61657a;
  font-size: 15px;
}

.slider_btn a {
  margin-top: 20px;
}

.slider_btn_one {
  margin-right: 0px;
}

.slider_btn_two {
  margin-right: 15px;
  color: #2b343b;
  border: 1px solid #2b343b;
  background: transparent;
}

.slider_btn_two.more-link::before {
  background: #4ba755;
}

.slider_btn_one:hover {
  color: #fff;
}

.slider_btn_two:hover {
  color: #fff;
  border: 1px solid #4ba755;
}

.slides_wrapper {
  position: relative;
}

.slides__preload_wrapper {
  background: #4ba755 none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.slides__preload_wrapper .spinner {
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  top: 50%;
}

/*  Carousel   Nav  
  -------------------------------------*/

.slider_home .st-swiper-button-nav {
  color: #333;
  font-size: 15px;
  height: 40px;
  left: 20px !important;
  line-height: 29px;
  margin-top: -77px;
  position: absolute;
  text-align: center;
  top: 100%;
  width: 40px;
  border-radius: 50%;
  padding-right: 0px !important;
  padding-top: 5px;
}
.slider_home .st-swiper-button-nav i {
  font-size: 18px;
  color: #222;
  z-index: 999;
  position: absolute;
  right: auto;
  margin-left: 537px;
  left: auto;
}
.slider_home .st-swiper-button-next {
  right: 0px;
  left: auto !important;
  margin-right: 30px;
}
.slider_home .st-swiper-button-prev {
  margin-right: 25px !important;
  left: auto !important;
  right: 0px !important;
}
.slider_home .st-swiper-button-nav {
  width: 100%;
  margin: auto;
  margin-top: -78px;
}
/*  Carousel Dots  
  -------------------------------------*/
.slider_home .swiper-pagination {
  width: 100%;
  padding-right: 70px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  position: relative;
  justify-content: flex-end;
  display: flex;
  position: relative;
}

.slider_home .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #fff;
  text-align: center;
  border-radius: 25px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  margin-bottom: 44px !important;
}
.slider_home .swiper-pagination-bullet:nth-child(1) {
  margin-right: 20px;
}
.slider_home .swiper-pagination-bullet-active {
  background: hsl(73, 100%, 40%);
  width: 10px;
  height: 10px;
}

/*----------------------------------------------------
     3. About Section
  ------------------------------------------------------*/
.about-section,
.organic_farming_content {
  padding: 120px 0px 105px;
}

.about-section img {
  width: 100%;
}
.organic_farming_content img {
  width: 100%;
  height: auto;
  display: block;
  margin: 30px 0;
}
.organic_farming_content ul {
  padding-left: 20px;
  list-style: disc;
}
.about_item_tb h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #2b343b;
}

.about_item {
  border-bottom: 1px solid #eee5e5;
  padding-bottom: 17px;
  margin-bottom: 24px;
  text-align: left;
}

.about_item:nth-child(3) {
  border-bottom: none;
  margin-bottom: 0px;
}

/*----------------------------------------------------
    4. Service Section
  ------------------------------------------------------*/

.service-section {
  padding-bottom: 120px;
  background: #f6f8ff;
  position: relative;
}

.service-item {
  position: relative;
  transition: transform 0.5s ease;
}

.service_text {
  border: 1px solid #e0e2ec;
  padding: 25px 25px 33px;
  position: relative;
}
.service_text p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.service_text h4{
      max-width: 325px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
}

.serv_link {
  position: absolute;
  bottom: 0px;
  background: #e7eaee;
  padding: 6px 12px;
  border-radius: 50%;
  margin-bottom: -19px;
  left: 0px;
  margin-left: 27px;
  font-size: 15px;
}

.img_serv {
  position: relative;
  overflow: hidden;
  height: 216px;
}

.img_serv img {
  transition: transform 0.3s ease;
  transform: scaleX(1);
  border: 1px solid #e0e2ed;
}

.service-item:hover img {
  transition: transform 0.5s ease;
  transform: scale(1.2);
}

.service_text:after {
  position: absolute;
  width: 0%;
  height: 1px;
  background: #4ba755;
  content: '';
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.service-item:hover .service_text:after {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.service-item:hover .serv_link {
  background: #4ba755;
  color: #fff;
  left: 74%;
}

/*
      Animate Icon Bounce
  ---------------------------------------*/
.animate_icon {
  display: block !important;
}

.animate_item {
  position: absolute;
  z-index: 1;
}

.animate_item1 {
  left: 6%;
  top: 14%;
}

.animate_item2 {
  right: 8%;
  top: 14%;
}

.animate_item3 {
  left: 5%;
  bottom: 13%;
}

.animate_item4 {
  right: 7%;
  bottom: 11%;
}
/* bounce-animate */
.bounce_animate {
  animation-name: organic-animate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: organic-animate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: organic-animate;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: organic-animate;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

/*----------------------------------------------------
     5. Video section
  ------------------------------------------------------*/

.video-section {
  padding: 120px 0px 105px;
  background-position: 50% 0%;
}

.video_wrp {
  text-align: center;
  width: 75%;
  margin: auto;
}

.video_wrp p {
  color: #4ba755;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 12px;
}

.video_wrp h3 {
  color: #fff;
  font-size: 35px;
  line-height: 58px;
  margin-bottom: 28px;
}

.play_btn i {
  color: #fff;
  background: #4ba755;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 3px;
}

.play_btn {
  border: 1px solid #474841;
  height: 64px;
  line-height: 50px;
  display: inline-block;
  padding: 6px;
  border-radius: 3px;
  background: transparent;
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  transition: opacity 0.3s ease-out;
}
.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}
.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  transform: translate(0);
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

/*  Fun Facts Section
  ---------------------------------*/

.funfact_wapr.row {
  width: 100%;
  margin: auto;
  margin-top: 60px;
}

.funfact_wapr {
  overflow: hidden;
}

.facts_wrapper {
  text-align: left;
  padding-left: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.4s ease 0s;
}

.funfact_wapr .col-sm-4 {
  padding-top: 20px;
  margin-top: 18px;
  text-align: right;
}

.facts_wrapper:hover .icon-lay i {
  color: #fff;
  background: #4ba755;
  transition: all 0.4s ease 0s;
  box-shadow: 4px 2px 26px 7px rgba(21, 10, 10, 0.11);
  border: 1px solid #4ba755;
}

.funfact_wapr .icon-lay i {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -31px;
  background: transparent;
  border-radius: 50%;
  padding: 17px;
  color: #f9f9f9;
  font-size: 30px;
  transition: all 0.4s ease 0s;
  border: 1px solid #f9f9f9;
  margin-left: 35px;
}

.facts_wrapper h4 {
  margin-top: 0px;
  margin-bottom: 7px;
  letter-spacing: 3px;
  font-size: 35px;
  color: #fff;
}

.facts_wrapper h4 span {
  font-size: 28px;
  color: #fff;
}

.facts_wrapper h5 {
  letter-spacing: 1px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: normal;
  color: #fff;
  font-family: 'Karla', sans-serif;
}

/*----------------------------------------------------
      6. Process Section
  ------------------------------------------------------*/

.img_process {
  position: relative;
}

.img_process span {
  position: absolute;
  left: 50%;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 10px 0px #bbb3b3;
  width: 30px;
  line-height: 30px;
  text-align: center;
  margin-left: -50px;
}

.process_text h4 {
  margin-top: 25px;
  margin-bottom: 13px;
}

.process_text {
  text-align: center;
}

.process-item {
  text-align: center;
}

.angle_icon {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 32px;
  margin-right: -70px;
}
.process-section .col-sm-12:last-child .angle_icon {
  display: none;
}
/*----------------------------------------------------
      7. Why Choose Us Section
  ------------------------------------------------------*/
.whychose-section {
  padding-top: 70px;
  background-repeat: no-repeat;
  background-position: top center;
}

.whychose_wrp .base-header {
  padding-top: 80px;
}

.whychose-section .col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
}

.whychose_bg {
  background-image: url('/assets/images/slider6.webp');
  background-position: left center;
  background-repeat: no-repeat;
  height: 557px;
  margin-left: 20px;
  margin-top: -50px;
  background-size: contain ;
}

.whychose_wrp {
  top: 0px;
  background-image: url('/assets/images/slider6.webp');
  background-position: right center;
  background-repeat: no-repeat;
  height: auto;
  margin-right: 15px;
  position: relative;
  padding-left: 80px;
  padding-bottom: 55px;
  margin-left: -50px;
  padding-right: 80px;
}

.special_ser_item {
  padding-left: 71px;
  margin-top: 5px;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.special_ser_item:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.special_ser_icon {
  position: absolute;
  left: 0px;
  top: 6px;
  color: #fff;
  font-size: 45px;
  line-height: 1em;
  margin-bottom: 25px;
}

.special_ser_item p {
  color: #fff;
  opacity: 0.9;
}

.whychose_wrp .base-header small,
.whychose_wrp .base-header h3,
.whychose_wrp h4 {
  color: #fff;
}

.whychose_wrp .base-header h3::after,
.whychose_wrp .base-header h3::before {
  background: #fff;
}

.whychose_wrp .base-header h3::before {
  background: #ddd;
}

/*----------------------------------------------------
       8.  Featured Products  Section
  ------------------------------------------------------*/
.product-section {
  padding-bottom: 120px;
  padding-top: 27px;
}

.product_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0.25fr));
}
.product_wrp {
  text-align: left;
  padding: 30px 20px;
  background: #ffff;
  position: relative;
  height: 315px;
  border: 1px solid #f2f2f2;
  min-width: 220px;
}
.product_wrp .product_img {
  height: 145px;
}
.product_img img {
  height: auto;
/*   width: 200px !important; */
/*   width: auto; */
  width: 100%;
  object-fit: contain;
  max-height: 135px;
  transition: transform 0.6s;
}
.product_wrp:hover .product_img img {
  transform: translateY(-10px);
}
.on_sale {
  position: absolute;
  background: #4ba755;
  color: #fff;
  padding: 0px 13px;
  border-radius: 5px;
  top: 10px;
  left: 10px;
}

.product_rating li {
  display: inline-block;
  color: #4ba755;
  margin: 0 2px 7px;
}

.product_info h5 {
    margin-bottom: 5px;
    margin-top: 20px;
    width: 176px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    font-size: 14px !important;
}

.product_info span {
  width: auto;
  position: relative;
}
.cut_price {
  text-decoration: line-through;
  text-decoration-thickness: 1.3px;
  color: #c50505 !important;
}
.prod_add_cart_btn {
  background: #4ba755;
  color: #fff;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  margin: 0px 2px;
}
.prod_out_of_stock {
  color: #c50505;
  padding: 2px 10px;
  border-radius: 6px;
  width: -webkit-max-content;
  width: max-content;
  margin: 0px 2px;
  /* margin-top: 30px; */
  /* font-weight: 600; */
}

.product_price {
  color: #3c4851;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 120px;
  margin: 0 auto;
  font-size: 17px;
}

.eye_outer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.eye_outer a {
  position: static !important;
  margin: 0px !important;
  /* cursor: pointer; */
}
/*----------------------------------------------------
      9. Pricing Section
  ------------------------------------------------------*/

.pricing-section {
  padding-bottom: 0px;
  background: #f6f8ff;
  background: url('/assets/images/bg_2.webp') no-repeat top center;
  background-position: 50% 0%;
}

.pricing-box {
  position: relative;
  text-align: center;
  border: 1px solid #f4eeee;
  padding-bottom: 40px;
  background: #fff;
}

.pricing-box.active .more-link::before {
  background: #2b343b;
}

.pricing-box.active h4 {
  background: #4ba755;
}

.pricing-box ul {
  margin-bottom: 13px;
}

.pricing-box ul li {
  list-style: none;
  border-bottom: 1px solid #f4eeee;
  padding: 12px 0px;
}

.pricing-box ul li:last-child {
  border: none;
}

.pricing-box h4 {
  font-size: 22px;
  text-transform: capitalize;
  background: #2b343b;
  color: #fff;
  padding: 25px 0px;
  margin-bottom: 40px;
}

.pricing-box h2 {
  font-size: 50px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 20px;
}

.pricing-box h2 span {
  font-size: 18px;
}

.pricing-section .more-link {
  margin-top: 10px;
  background: #2b343b;
  color: #fff;
}

.pricing-box .more-link::before {
  background: #4ba755;
}

.pricing-box a:hover {
  color: #fff;
}

.pricing-box.active {
  border: 1px solid #4ba755;
}

.pricing-box.active .more-link {
  background: #4ba755;
  color: #fff;
}

.pricing-box.active .more-link:hover {
  color: #fff;
}

/* bounce-animate */
.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
/* End : bounce-animate */

/*----------------------------------------------------
    10. Index Section
  ------------------------------------------------------*/
.project-section {
  padding-top: 11px;
  padding-bottom: 12px;
}

.project-section .base_header_left {
  /* padding-bottom: 56px; */
}

.project-section .container-fluid {
  padding: 0px;
}

.project-section .project-slider-2 .slick-center {
  opacity: 1;
}

.project-section .col-md-12 {
  padding-left: 0px;
}

.project-section .col-md-12.slick-active {
  opacity: 1;
}

.project_slide_img {
  position: relative;
  overflow: hidden;
}

.project_slide_img img {
  transition: transform 0.3s ease;
  transform: scaleX(1);
  height: 395px;
  width: 100%;
  object-fit: cover;
}

.project-item:hover .project_slide_img img {
  transition: transform 0.3s ease;
  transform: scale(1.2);
}

.project_slide_img:after {
  position: absolute;
  content: '';
  /* background: #333; */
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.5;
  left: 0px;
}

.project_text {
  position: absolute;
  bottom: 0;
  padding: 30px;
}

.project_text h4 {
  color: #fff;
  margin-bottom: 5px;
}

.project_text .project_link {
  color: #4ba755;
  font-size: 16px;
}

.project-section .arrows-slick {
  text-align: right;
  margin-top: 35px;
}

.project-section .slick-arrow {
  border: none;
  outline: none;
  font-size: 30px;
  text-align: center;
  width: 45px;
  height: 45px;
  line-height: 42px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  background: transparent;
  border: 1px solid #ebebec;
  color: #b9b3b3;
  border-radius: 2px;
}

.project-section .slick-arrow:hover {
  background: #4ba755;
  color: #fff;
  border: 1px solid #4ba755;
}

.project_btn {
  margin-top: 50px;
}

.project_slider_one .slick-initialized .slick-slide {
  opacity: 0.5;
}
.project_slider_one .slick-slide.slick-active {
  opacity: 1;
}
.gallery-page .project-section .col-md-12 {
  padding-bottom: 15px;
}
.gallery-page .project-section {
  padding-bottom: 105px;
}
/*----------------------------------------------------
      11. Team Section
  ------------------------------------------------------*/

.team-section {
  padding-bottom: 115px;
  background-image: url('/assets/images/bg_3.webp');
}

.team_wrp {
  background: #fff;
  padding: 50px 40px 45px;
  position: relative;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.07);
  height: 441px;
}
.height_custom{
  min-height: 550px;
  height: 798px;
  overflow: scroll;
}
.height_custom .team_img img{
  max-height: 220px;
}

.team_wrp:hover {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.team_wrp:hover .team_img img {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  transition: all 1000ms ease;
}

.team_wrp:after {
  content: '';
  position: absolute;
  max-width: 100%;
  width: 100%;
  display: block;
  height: 157px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  background: url('/assets/images/team_bg.webp') no-repeat top center;
}

.team_img img {
  border-radius: 50%;
  width: 214px;
  height: auto;
  border: 5px solid #fff;
  box-shadow: 0px 1px 7px 2px rgba(0, 0, 0, 0.12);
  max-height: 178px;
  object-fit: cover;
}

.team_img {
  text-align: center;
  position: relative;
  z-index: 7;
}

.team_info {
  text-align: center;
}

.team_info h4 {
  margin-top: 25px;
  margin-bottom: 3px;
}

.team_info h6 {
  margin-bottom: 20px;
  color: #61657a;
}

.team_info li {
  display: inline-block;
  margin: 0px 2px;
}

.team_info i {
  color: #4ba755;
  margin: 10px 2px 0px;
  font-size: 15px;
  border: 1px solid #4ba755;
  width: 32px;
  height: 32px;
  line-height: 29px;
  border-radius: 50%;
}

.team_info i:hover {
  color: #fff;
  background: #4ba755;
}
.team-page .team_section {
  padding-top: 102px;
}
.team-page .team_wrp {
  margin-bottom: 30px;
}
/*----------------------------------------------------
     12. Testimonial Section  
  ------------------------------------------------------*/
.testi-section {
  padding-bottom: 210px;
  padding-top: 0px;
}

.testi-section .base-header {
  margin-bottom: 60px;
}

.testi_wrp {
  padding: 15px 0px 40px 34px;
  margin-bottom: 0px;
  border-radius: 40px 0px 0px;
  overflow: hidden;
  display: flex;
  grid-column-gap: 50px;
  column-gap: 50px;
  grid-row-gap: 40px;
  row-gap: 40px;
}
.testi_wrp img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
  /* object-fit: contain; */
}
.testi_info {
  position: relative;
}

.testi_info:after {
  position: absolute;
  top: 0px;
  font-size: 59px;
  color: #eaece3;
  margin-top: -6px;
  z-index: -9;
  left: 0px;
  margin-left: -6px;
  content: '\e89e';
  font-family: 'custom-icons';
  font-weight: bold;
}

.testi_info p {
  font-size: 16px;
  line-height: 32px;
  color: #61657a;
  margin-bottom: 15px;
  text-align: justify;
}

.testi_img {
  position: relative;
}

.testi_img h4 {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 16px;
  color: #2b343b;
}

.testi_img h4 span {
  display: block;
  font-size: 15px;
  color: #4ba755;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
  font-family: 'Karla', sans-serif;
}

.testi_sing_img img {
  border-radius: 50%;
  width: 191px;
  position: absolute;
  object-fit: scale-down;
}

.testi_sing_img img:nth-child(3) {
  left: 0px;
  width: 160px;
  top: 0px;
  margin-top: 15px;
}

.testi_sing_img img:nth-child(2) {
  width: 100px;
  left: 0px;
  top: 0px;
  margin-top: -110px;
  margin-left: 95px;
}

.testi_sing_img img:nth-child(1) {
  right: 0px;
  top: 0px;
  margin-top: -70px;
}

.testi_sing_img img:nth-child(4) {
  width: 135px;
  top: 0px;
  margin-top: 180px;
  left: 0px;
  margin-left: 60px;
}

.testi_sing_img {
  position: relative;
  margin-top: 15px;
}

/* Testimonial Nav*/

.testi-section .owl-nav div {
  top: 100%;
  font-size: 17px;
  left: 50px;
  color: #000;
}

.testi-section .owl-nav .owl-prev {
  left: 20px !important;
}

.testi-section .owl-nav div:hover {
  color: #4ba755;
}

/*================================================
      13. Blog Section
  ==================================================*/

.blog-section {
  /* background: #F1F6FA; */
  padding-bottom: 90px;
  padding-top: 120px;
}

.blog_wrp_list {
  margin-bottom: 30px;
}

.blog_info {
  background: #fff;
  padding: 25px 30px 20px;
  position: relative;
  z-index: 9;
}

.blog_info h4 {
  margin-top: 0px;
  border-bottom: 1px solid #f0eaea;
  padding-bottom: 25px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.blog_info h4:hover {
  color: #4ba755;
}

.blog_date {
  display: inline-block;
  font-size: 14px;
}

.blog_date span i {
  margin-right: 5px;
}

.blog_read a {
  color: #4ba755;
  text-transform: capitalize;
  font-size: 16px;
}

.blog_read {
  float: right;
  transition: all 0.3s ease-in-out;
}

.blog_read i {
  margin-left: 3px;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 1px;
  color: #4ba755;
}

.blog_read:hover i {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  left: 3px;
}

.blog_img img {
  height: auto;
  transition: all 0.4s ease-out 0s;
}

.blog_img {
  overflow: hidden;
}

.blog_wrp:hover .blog_img img {
  transform: scale(1.1) rotate(3deg);
  transition: all 0.4s ease-out 0s;
}

.blog_wrp {
  overflow: hidden;
}

/*---------------------------------------------------- 
    14. Client Section 
  ------------------------------------------------------*/
.client-section {
  padding-top: 70px;
  padding-bottom: 72px;
}

.client-box {
  cursor: pointer;
  -ms-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0);
  -o-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0);
}

.client-box,
.client-box img,
.client-box:hover,
.client-box:hover img {
  transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.client-section .owl-theme .owl-controls {
  margin-top: 10px;
}

.client-section .owl-carousel .owl-item img {
  width: auto;
  display: inline-block;
}

.client-box:hover img {
  opacity: 0.8;
}

.client-box {
  width: 100%;
  text-align: center;
  cursor: pointer;
  -ms-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0);
  -o-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0);
  line-height: 120px;
  padding-right: 10px;
  padding-top: 10px;
}

.client-box,
.client-box img,
.client-box:hover,
.client-box:hover img {
  transition: all ease 0.5s;
}

/*----------------------------------------------------
     15. Contact  Section
  ------------------------------------------------------*/

.contact-section {
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
}

.contact_wrp {
  padding: 75px 80px 80px;
  background: #f6f8ff;
  position: relative;
}

.contact_bg {
  background: #f6f8ff url('/assets/images/contact_bg.webp');
  background-position: right center;
  background-repeat: no-repeat;
  height: 673px;
  width: 36%;
  float: right;
  background-size: cover;
  position: relative;
  z-index: 8;
}
.contact_bg2 {
  position: absolute;
  background: #2b343b;
  bottom: 0px;
  width: 100%;
  height: 190px;
}
.contact-form {
  padding-right: 30px;
}

.contact-form #contact-form .con-field {
  outline: inherit;
  border-width: medium medium 1px;
  border-style: none none solid;
  border-color: -moz-use-text-color -moz-use-text-color #4ba755;
  -moz-border-top-colors: none;
  -moz-border-right-colors: none;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  border-image: none;
  width: 100%;
  margin-bottom: 30px;
  padding: 5px 15px;
  line-height: 38px;
  border: 1px solid #9da7ae;
  background: transparent;
  height: auto;
  border-radius: 0px;
}

.contact-form #contact-form .con-field:focus {
  border: 1px solid #4ba755;
  box-shadow: none;
}

.contact-form #contact-form::-webkit-input-placeholder {
  color: #9b9ba3;
  font-size: 14px;
  text-align: left;
}

.contact-form #contact-form:-moz-placeholder {
  color: #9b9ba3;
  font-size: 14px;
  text-align: left;
}

.contact-form #contact-form::-moz-placeholder {
  color: #9b9ba3;
  font-size: 14px;
  text-align: left;
}

.contact-form #contact-form:-ms-input-placeholder {
  color: #9b9ba3;
  font-size: 14px;
  text-align: left;
}

.contact-form textarea {
  line-height: 16px !important;
  padding-top: 20px !important;
  height: 140px !important;
}

.submit-contact {
  padding: 0;
  padding-right: 30px;
  padding-left: 30px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: capitalize;
  display: inline-block;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 0;
  color: white;
  position: relative;
  margin: 0px 0px 0px 0px;
  transition: all 0.4s ease;
  background: #4ba755;
  font-size: 16px;
  font-weight: 500;
  border-radius: 2px;
}

/* .submit-contact:hover {
  background-color: #3b3f46;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
} */

.contact-form #contact-form #msg {
  color: #f54337;
}

/*----------------------------------------------------
     16. Footer Section
  ------------------------------------------------------*/
.home-page .footer-section {
  padding-top: 285px;
  margin-top: -300px;
}

.footer-section {
  float: left;
  width: 100%;
  padding: 100px 0 0 0;
  color: #fff;
  background: #2b343b;
}

.footer-section .container {
  position: relative;
}

.footer-section .widget {
  padding-bottom: 0px;
  margin-bottom: 17px;
  padding: 0px;
  background: transparent;
}

.footer-section .widget h5 {
  color: #fffcf4;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-size: 22px;
  margin-bottom: 32px;
}

.footer-section .widget h3 {
  color: #fff;
  font-weight: 900;
  letter-spacing: 3px;
  margin-bottom: 25px;
}

.footer-section .widget p {
  color: #d5d5d5;
  margin-top: 15px;
  float: left;
  margin-bottom: 16px;
}

.footer-section .widget img {
  margin-top: 3px;
  width: 91px;
    height: auto;
}

.footer_soc {
  margin-bottom: 15px;
}

.footer_socil {
  overflow: hidden;
  width: 100%;
}

.footer_socil .list-icons {
  padding-left: 0;
  list-style: none;
}

.footer_socil .list-icons li {
  float: left;
  margin-bottom: 30px;
  margin-right: 6px;
}

.footer_socil .list-icons li a {
  color: #acaba9;
  font-size: 18px;
  padding-right: 7px;
}

.footer_socil .list-icons li a:hover {
  color: #4ba755;
  text-decoration: underline;
}

.quick_lnk ul li {
  list-style: none;
  padding-bottom: 12px;
  padding-left: 20px;
}

.quick_lnk ul li::after {
  position: absolute;
  content: '>';
  left: 0;
  margin-left: 16px;
  width: 12px;
  height: 1px;
  margin-top: 0px;
  color: #4ba755;
}

.quick_lnk ul li a {
  color: #d5d5d5;
}

footer .quick_lnk li a:hover {
  color: #4ba755;
  text-decoration: underline;
}

.footer_recent_blog li {
  border-bottom: 1px solid #3c3939;
  padding-bottom: 18px;
  margin-bottom: 13px;
  overflow: hidden;
}

.footer_recent_blog li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid transparent;
}

.footer_recent_blog img {
  width: 88px;
  float: left;
  margin-right: 16px;
  overflow: hidden;
  height: 80px;
}

.footer_recent_blog .post_cont {
  overflow: hidden;
  display: block;
}

.post-date {
  color: #7d7d7d;
}

.footer_recent_blog .post_cont i {
  margin-right: 7px;
  color: #4ba755;
  font-size: 14px;
}

.footer_recent_blog .post_cont a span {
  margin-top: 10px;
  line-height: 23px;
  color: #d5d5d5;
  overflow: hidden;
  display: block;
}

.footer_recent_blog .post_cont a span:hover {
  color: #4ba755;
}

.footer-section .email_field {
  background: #3b3f46;
  box-shadow: none;
  border: none;
  color: #ddd;
  line-height: 40px;
  padding-left: 30px;
  width: 100%;
  margin-top: 5px;
  border: 1px solid transparent;
}

.footer-section .email_field:focus {
  border: 1px solid #4ba755;
}

.news_letter_wrp p {
  margin-top: -2px !important;
}

.news_letter_wrp .submit-contact {
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  border-radius: 30px;
  font-size: 15px;
}

.footer-social-links ul {
  margin-left: -39px;
}

.footer-social-links ul li {
  display: inline-block;
  position: relative;
  margin: 10px 5px 0;
  line-height: 1em;
  vertical-align: top;
}

.footer-social-links ul li a {
  display: inline-block;
  text-align: center;
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-size: 21px;
  border: 1px solid #bababa;
  border-radius: 50%;
  color: #bababa;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.subfooter {
  width: 100%;
  text-align: center;
  padding: 35px 0px;
  color: #bababa;
  margin-top: 85px;
  background: #252d32;
  position: relative;
}

.subfooter p {
  color: #acaba9;
  margin: 0px;
  text-transform: capitalize;
}

.subfooter p a {
  color: #acaba9;
}

.scrollup {
  color: #4ba755;
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin-right: 15px;
  margin-bottom: -98px;
  z-index: 7;
  background: transparent;
  border: none;
}

.scrollup span {
  font-size: 15px;
  background: transparent;
  padding: 9px 10px;
  border: 2px solid #4ba755;
  border-radius: 50%;
  color: #4ba755;
}

.scrollup span:hover {
  background: #4ba755;
  color: #fff;
}

.scrollup:hover,
.scrollup:active,
.scrollup:focus {
  color: rgba(255, 255, 255, 0.9);
}

/*----------------------------------------------------
      Single Page
  ------------------------------------------------------*/
/* Single Page Header
  ================================= */

header {
  position: relative;
  text-align: center;
  color: #fff;
  background-color: rgba(32, 32, 32, 0.74);
  height: 700px;
}

header .container {
  padding-top: 70px;
  position: relative;
  z-index: 7;
  text-align: center;
}

header.blog-banner {
  color: #fff;
  box-sizing: border-box;
  height: 250px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% 350px;
}

header {
  position: relative;
  text-align: center;
  color: #fff;
  background-color: rgba(198, 227, 221, 0.39);
  height: 700px;
}

.blog-header {
  padding-left: 0px;
  text-align: left;
}

.blog-header h4 {
  color: black;
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 1px;
  display: block;
  margin-bottom: -25px;
  font-weight: 400;
  font-family: 'Karla', sans-serif;
}

.blog-header h4 a {
  color: black;
}

.blog-header h3 {
  color: black;
  text-transform: capitalize;
  font-size: 45px;
  letter-spacing: 0px;
  padding-bottom: 15px;
  font-weight: 700;
}

.blog-header h4 a:hover {
  color: #4ba755;
}

/*----------------------------------------------------
      Call to action Section
  ------------------------------------------------------*/
.cta_bg {
  background-image: url('/assets/images/cta_bg.webp');
  background-position: right center;
  background-repeat: no-repeat;
  height: 300px;
  width: 45%;
  float: right;
  background-size: cover;
}

/* =================================
      About page 
  ================================= */
.about-page .process-section {
  padding-top: 0px;
  padding-bottom: 120px;
}
.about-page h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #ffffff;
}
.about-page .testi-section {
  background: #f1f6fa;
  padding-top: 117px;
  padding-bottom: 117px;
}
/* =================================
      Service page 
  ================================= */

.service-page .service-section {
  padding-bottom: 70px;
}

.service-page .service-item {
  margin-bottom: 50px;
}

/* =================================
      Index page
  ================================= */
.project_pg_proj {
  padding-bottom: 105px;
}

.project_pg_proj .col-md-12 {
  margin-bottom: 15px;
}

/* =================================
      Index page  Two
  ================================= */
.work-section {
  background: #fff;
}

.work-section .row .col-xs-12:nth-child(4) {
  margin-left: 16.7%;
}

.work-section .row .col-xs-12:nth-child(5) {
  margin-left: -16.7%;
}

.work-section .row .col-md-6 {
  max-width: 33.33%;
}

/* Work items ------------------*/

.single-project-item {
  background-image: url(/assets/images/work-1.webp);
}

.project-bg-2 {
  background-image: url(/assets/images/work-2.webp);
}

.project-bg-3 {
  background-image: url(/assets/images/work-3.webp);
}

.project-bg-4 {
  background-image: url(/assets/images/work-4.webp);
}

.project-bg-5 {
  background-image: url(/assets/images/work-5.webp);
}

.project-bg-6 {
  background-image: url(/assets/images/work-6.webp);
}

.project-bg-7 {
  background-image: url(/assets/images/work-7.webp);
}

.project-bg-8 {
  background-image: url(/assets/images/work-8.webp);
}

.single-project-item {
  background-color: #ddd;
  background-position: center center;
  background-size: cover;
  height: 150px;
  width: 150px;
  margin-bottom: 30px;
}

.projects-titles {
  list-style: outside none none;
  text-align: center;
  margin-bottom: 45px;
  margin-top: -5px;
  width: 100%;
}

.projects-titles li {
  color: #2b343b;
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  transition: all 0.3s ease 0s;
  margin-left: 5px;
  text-transform: uppercase;
  position: relative;
  padding: 10px 40px;
  border: 1px solid #ccd2d7;
  font-size: 15px;
  margin-bottom: 10px;
}

.project-hover span,
.project-hover a {
  color: #fff;
}

.projects-titles li.active {
  color: #fff;
  background: #4ba755;
  border: 1px solid #4ba755;
}

.project-hover h6 {
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
  letter-spacing: 0px;
  margin-top: 24px;
  margin-bottom: 2px;
  position: relative;
}

.project-hover span {
  display: block;
  font-size: 15px;
  font-weight: normal;
  padding-top: 0px;
  text-transform: capitalize;
  position: relative;
}

.single-project-item .project-link i {
  position: relative;
  font-size: 15px;
  background: #fff;
  color: #333;
  border-radius: 50%;
  padding: 8px 10px;
}

.project-hover {
  position: relative;
  height: 100%;
}

.project-hover::before {
  background: #292626;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease 0s;
}

.single-project-item:hover .project-hover:before {
  opacity: 0.7;
  transition: all 0.4s ease 0s;
}

.single-project-item:hover .project_cnt {
  opacity: 1;
  transition: all 0.4s ease 0s;
  padding-top: 123px;
}

.project_cnt {
  text-align: center;
  padding-top: 114px;
  opacity: 0;
  transition: all 0.4s ease 0s;
}

/* =================================
      Contact page 
  ================================= */
.contact-page .contact-section {
  padding-top: 115px;
  padding-bottom: 120px;
  background: #f1f6fa;
}

.contact-page .contact-section .contact-form {
  padding-right: 20px;
}

.contact-page .contact-section .contact_wrp {
  padding: 0px;
  background: transparent;
  position: relative;
}

.map-container {
  width: 100%;
  height: 500px;
  border: none;
  margin-bottom: 50px;
  max-width: 97.2% !important;
}

.contact-page .contact-section .contact-form #contact-form textarea {
  height: 155px !important;
}

.contact_pg_address {
  padding: 40px 40px 36px;
  border: 1px solid #9da7ae;
}

.contact_pg_address h3 {
  margin-bottom: 27px;
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 38px;
  margin-top: -2px;
}

.single_con_add p {
  margin: 0;
  color: #515050;
  margin-top: 0px;
}

.single_con_add p:nth-child(2) {
  margin-top: -10px;
  font-weight: bold;
}

.contact-page .contact-section .single_con_add {
  overflow: hidden;
  margin-bottom: 0px;
  margin-top: 15px;
  padding: 10px 0px;
}

.contact-page .contact-section .single_con_add a {
  display: block;
  float: left;
  color: #515050;
}

.contact-page .contact-section .single_con_add a i {
  font-size: 20px;
  margin-top: 1px;
  color: #515050;
  border: 1px solid #515050;
  border-radius: 50%;
  padding: 10px 12px;
  margin-right: 15px;
}

.contact-page .contact-section .single_con_add span {
  display: block;
  padding-left: 45px;
}

/* =================================
      Pricing page 
  ================================= */
.price_pg {
  background: #f6f6f8;
}

/* =================================
      Service page 
  ================================= */
.single_service {
  padding: 50px 0px;
}

.single_service_left p {
  margin-bottom: 20px;
}

.service_detail_bottom .service_botom_text {
  padding-right: 15px;
}

.single_service_left img {
  transition: all 0.3s ease-out;
}

.single_service_left img:hover {
  transform: scale3d(1.01, 1.01, 1);
  transition: all 0.3s ease-out;
}

.single_service_left {
  overflow: hidden;
}

.service_detail_bottom h4 {
  margin-top: 50px;
  margin-bottom: 20px;
}

.service_detail_bottom .service_botom_text1 {
  padding-left: 15px;
}

.single_service_right {
  padding-left: 0px;
}

.single_service .service_botom_text,
.single_service .service_botom_text1 {
  margin-top: 12px;
}

.single_service .service_botom_text1 {
  margin-top: 20px;
}

.single_service_left_botom .sing_service_item .icon-serv {
  margin-top: 0px;
  margin-bottom: 25px;
}

.single_service_left_botom .sing_service_item {
  padding: 40px 35px 30px;
  margin-bottom: 0px;
  background: #f1f6fa;
  text-align: center;
}

.single_service h4 {
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
  margin-top: 0px;
}

.single_service_left h4 {
  margin-bottom: 20px;
  margin-top: 35px;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0px;
}

.single_service_cat h4 {
  margin-top: 0px;
}

.single_service_cat {
  margin-bottom: 30px;
}

.single_service_cat ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single_service_cat ul .active {
  border-left: 2px solid #4ba755;
}

.single_service_cat ul li {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 1px;
  text-align: left;
  padding: 18px 5px 18px 46px;
  border-radius: 2px;
  background: #f1f6fa;
  border-left: 2px solid transparent;
}

.single_service_cat ul li:hover a {
  color: #4ba755;
}

.single_service_cat ul li:hover {
  border-left: 2px solid #4ba755;
}

.single_service_cat ul li:hover:after {
  color: #4ba755;
}

.single_service_cat ul li a {
  font-size: 16px;
  display: block;
  position: relative;
  z-index: 99;
  color: #61657a;
  text-transform: capitalize;
}

.single_service_cat ul li::after {
  content: '\e8cc';
  font-family: 'custom-icons';
  left: 6px;
  top: 18.5px;
  color: #9fcc02;
  position: absolute;
  font-weight: bold;
  font-size: 9px;
  margin-left: 23px;
}

.service_contact {
  margin-bottom: 30px;
  background: #f1f6fa;
  padding: 30px 35px 15px;
}

.service_contact i {
  font-size: 16px;
  color: #4ba755;
}

.service_contact .fa-globe {
  position: relative;
  top: -25px;
}

.service_contact p {
  font-size: 16px;
  display: inline-block;
  margin-left: 14px;
  margin-bottom: 14px;
}

.download_brochur {
  background: #f1f6fa;
  padding: 30px 35px 25px;
  overflow: hidden;
}

.download_brochur ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.download_brochur .files li {
  position: relative;
  margin-bottom: 10px;
}

.download_brochur .files li a {
  position: relative;
  display: block;
  color: #515050;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  padding: 13px 26px;
  border-radius: 5px;
  border: 1px solid #4ba755;
  text-align: center;
  text-transform: uppercase;
}

.download_brochur .files li a:hover {
  background-color: #4ba755;
  border: 1px solid #4ba755;
  color: #fff;
}

.download_brochur .files li a:hover .fa {
  color: #fff;
  transition: all 0.4s ease-out 0s;
}

.download_brochur .files li a .fa {
  position: relative;
  line-height: 24px;
  padding-right: 8px;
  color: #4ba755;
}

.download_brochur p {
  margin-bottom: 25px;
  margin-top: -5px;
}
/* =================================
      14. Blog Page
  ================================= */

.blog_container {
  padding-bottom: 120px;
}

.blog-area {
  padding-top: 120px;
}

.blog_container .blog_box {
  margin-bottom: 40px;
  position: relative;
}

.blog_container .col-lg-6:nth-child(5) .blog_box,
.blog_container .col-lg-6:nth-child(6) .blog_box {
  margin-bottom: -6px;
}

.blog-area .blog_date_athor {
  margin-bottom: 30px;
}

.blog_container .blog_info_right h3 {
  font-size: 22px;
}

/* - Pagination */

.pagination {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.pagination .pager {
  text-align: center;
  width: 100%;
  margin: 0px 0px;
  position: relative;
  padding: 0;
}

.pager li {
  position: relative;
  padding: 0px 0px;
  display: inline;
}

.pager .pagi-big a {
  font-weight: 500;
  color: #fff;
  background: #4ba755;
}

.pager li a {
  border-radius: 0;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding: 0;
  color: #fff;
  font-weight: 500;
  padding: 11px 13px;
  border: 1px solid #ddd;
  background: transparent;
  color: #333;
}

.pager-icon {
  font-size: 14px;
  left: 0px;
  position: relative;
  top: 6px;
  font-weight: 700;
  letter-spacing: 2px;
}

.pager-icon:hover {
  color: #000;
}

.pagination .pager li > a:focus,
.pagination .pager li > a:hover {
  background-color: #4ba755;
  color: #fff;
}
/* -- Widget Area */

.widget-area {
  text-align: center;
  padding-top: 120px;
}
/* - Widget */

.widget {
  display: inline-block;
  margin-bottom: 45px;
  width: 100%;
  background: #f1f6fa;
  padding: 30px;
}
.widget iframe{
  padding: 0px 2px;
}
/* - Widget Search */

.widget {
  margin-bottom: 30px;
}

.widget-search {
  display: block;
  background: #f1f6fa;
  border: 1px solid #f2f1f0;
  padding: 5px 10px;
}

.widget-search .input-group {
  padding-bottom: 6px;
  padding-top: 6px;
}

.widget-search .input-group input {
  background: transparent none repeat scroll 0% 0%;
  border: 0px none;
  border-radius: 0px;
  transition: none 0s ease 0s;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  color: #8f8f8f !important;
  height: auto;
}

.widget-search .input-group button {
  background: #4ba755;
  border: medium none;
  font-size: 14px;
  color: #fff;
  padding: 5px 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.input-group-btn {
  left: 0px;
}
/* - Widget Title */

.widget .widget-title:after {
  position: absolute;
  background: #4ba755;
  bottom: 0;
  width: 65px;
  height: 2px;
  content: '';
  left: 0;
}

.widget .widget-title {
  font-size: 22px;
  text-transform: capitalize;
  text-align: left;
  letter-spacing: 0px;
  margin: -5px 0px 30px;
  position: relative;
  padding: 0px 0px 8px;
  position: relative;
}
/* - Categories Type */

.categories-type li {
  padding: 4px 10px;
  letter-spacing: 0.5px;
  color: #e1e1e1;
  list-style: square;
}

.widget-post-categories .categories-type {
  text-align: left;
  display: inline-block;
  margin-top: -10px;
  width: 100%;
  margin-left: 15px;
}

.categories-type li a {
  color: #515050;
}

.categories-type li:hover a {
  color: #4ba755;
}

.categories-type li span {
  float: right;
  clear: both;
}
/* - Recent Post */

.wiget-recent-post {
  background: #f1f6fa;
  padding: 30px;
}

.recnt_pst_imge {
  width: 40%;
  float: left;
  margin-right: 15px;
}

.recnt_pst_imge img {
  height: 69px;
}

.recent-post-box {
  display: inline-block;
  padding-bottom: 20px;
  width: 100%;
  position: relative;
}

.recent-post-box:last-of-type {
  border-bottom: none;
  padding-bottom: 0px;
}

.recent-title {
  text-align: left;
  display: inline-block;
  width: 52%;
}

.recent-title > a {
  font-size: 18px;
  margin-top: 5px;
  display: inline-block;
  color: #2b343b;
  line-height: 18px;
}

.recent-title > a:hover {
  text-decoration: underline;
}

.recent-title > span {
  font-size: 13px;
  color: #515050;
  margin-top: -8px;
  margin-bottom: 0px;
}

.recent-title > span i {
  color: #4ba755;
  margin-right: 4px;
}

/* - Widget Tags */

.widget-tags {
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
}

.widget-tags .widget-title {
  display: inline-block;
  width: 100%;
}

.widget-tags > a {
  color: #515050;
  text-decoration: none;
  border: 1px solid #ddd6d6;
  padding: 6px 13px;
  display: inline-block;
  margin-bottom: 3px;
  font-size: 13px;
  margin-right: 3px;
}

.widget-tags > a:hover {
  color: #fff;
  border-color: #4ba755;
  background: #4ba755;
}
/*
    Blog Page 
  ================================= */

.blog_container .blog_tx_warp {
  margin-bottom: 30px;
}

.post_by_area .post_img {
  background-position: -9px 0;
  background-repeat: no-repeat;
  border-radius: 50px;
  height: 78px;
  width: 78px;
  display: block;
  float: left;
}

.post_by_area .post_title h4 {
  margin-bottom: 0px;
  font-size: 18px;
}

.post_title {
  margin-bottom: 47px;
  margin-top: 35px;
  padding-left: 100px;
}

.widget.post_by_area {
  text-align: left;
}

.blog_container.single_blog_container .blog_info {
  padding: 28px 30px 30px;
}

.blog_quote {
  margin-top: 0px;
  border-bottom: 1px solid #ece3e3;
  padding-bottom: 15px;
}

.single_blog_container .blog_quote h3 {
  letter-spacing: 0px;
  display: block;
  margin-top: 28px;
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 26px;
}

.single_blog_container .marked {
  background: #f1f6fa;
  font-size: 20px;
  border-left: 2px solid #4ba755;
  padding: 30px;
  line-height: 30px;
}

.single_blog_container .marked span {
  color: #4ba755;
  font-size: 15px;
  display: block;
  margin-top: 8px;
  font-family: 'Karla', sans-serif;
}

.blog_quote_right p {
  position: relative;
  margin-top: 0px;
}

.blog_quote_icon_botom {
  font-size: 50px;
  position: absolute;
  right: 223px;
  top: 72px;
  color: #dcdcdc;
}

.blog_quote p {
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 0px;
}

/*=================================
     Post Tag and SHare 
  ================================= */
.post_share.footer_socil {
  width: 30%;
  text-align: right;
  margin-top: 30px;
}

.post_share .list-icons {
  padding-left: 0;
  list-style: none;
  text-align: right;
  float: right;
  margin-bottom: 0px;
}

.post_share .list-icons li {
  margin-bottom: 0px;
  margin-top: 5px;
}

.post_share .list-icons li a {
  padding-right: 0px;
  padding-left: 10px;
}

.post_tags {
  float: left;
  width: 70%;
  margin-top: 30px;
}

.post_tags h5 {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0px;
}

.post_tags a {
  background: #4ba755;
  color: #fff;
  padding: 6px 10px;
  margin: 0px 2px;
  border-radius: 2px;
}

/*=================================
    15. Single Blog Page 
  ================================= */

.single_blog_container {
  padding-bottom: 120px;
}

.single_blog_container .post_title h5 {
  margin-top: 0px;
}

.single_blog_container .post_by_area p {
  display: block;
  float: left;
  text-align: left;
  margin-top: -10px;
}

.single_blog_container .blog_info_right {
  padding: 23px 25px 15px 25px;
  background: #f7f8fa;
}

.single_blog_container .blog_info_right h3 {
  font-size: 26px;
  line-height: 33px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.blog-area img {
  width: 100%;
}

.single_blog_container .blog_info_right > p {
  margin-top: 15px;
  margin-bottom: 5px;
}

.single_blog_container .blog_dt h6 {
  font-size: 15px;
  margin-bottom: 0px;
  color: #fff;
  padding: 20px 15px;
  text-align: center;
}

.single_blog_container .blog_dt {
  position: absolute;
  top: 0px;
  background: #4ba755;
  left: 20px;
}

.single_blog_container .blog_post_date span:first-child {
  padding-left: 0px;
}

.single_blog_container .icon_coment {
  position: relative;
  top: 1px;
}

.blog_post_date i {
  margin-right: 5px;
}

.single_blog_container .blog_post_date {
  border-bottom: 1px solid #ece4e4;
  padding-bottom: 20px;
}

.single_blog_container .blog_post_date span {
  padding-left: 25px;
  color: #747474;
}

.single_blog_container .blog_post_date span a {
  color: #747474;
}

.single_blog_container .comment_imgg {
  width: 100px;
  float: left;
  margin-right: 25px;
}

.single_blog_container .comment_cont_wrp {
  position: relative;
}

.blog-post-list .entry-cover {
  margin-bottom: 30px;
  overflow: hidden;
}

.blog-post-list .entry-cover img {
  transition: all 0.4s ease 0s;
  display: block;
  width: 100%;
}

.blog-area .entry-title {
  margin-bottom: 0px;
}

.blog-area .blog-post-list .entry-title > a {
  text-decoration: none;
  color: #333;
  font-size: 24px;
  letter-spacing: 2px;
  position: relative;
  top: -29px;
}

.blog-area .blog-post-list .entry-title > a:hover {
  color: #5f5d5d;
}

.blog-post-list .entry-meta {
  color: #b9b9b9;
  letter-spacing: 1px;
  text-transform: capitalize;
  display: inline-block;
}

.blog-post-list .entry-meta span {
  margin-left: 0px;
  color: #b9b9b9;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-right: 22px;
}

.blog-post-list .entry-meta a {
  color: #8d8484;
  text-transform: capitalize;
  text-decoration: none;
}

.entry-date a {
  letter-spacing: 2px;
}

.blog-post-list .entry-meta span {
  margin-left: 0px;
  color: #b9b9b9;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-right: 22px;
}

.blog-post-list .entry-meta a {
  color: #5c5c5c;
  text-transform: capitalize;
  text-decoration: none;
}

.entry-content {
  margin-top: -27px;
}

.contact .blog-contact .contact-warper {
  background: #fff;
}

.contact .blog-contact .con-field {
  background: #f9f0f0;
}

.list-comments {
  padding-top: 0px;
}

.comments-section-title h4 {
  margin-top: 0px;
  font-size: 24px;
  margin-bottom: 23px;
  letter-spacing: 0px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 600;
  padding: 15px 0px;
}

.comments {
  padding: 0px;
  margin: 0px;
  margin-top: -40px;
}

.comments li {
  list-style: outside none none;
}

.comments li .comment {
  padding: 40px 0px 27px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  background: transparent;
  margin-top: 10px;
}

.comments li .comment img.comment-avatar {
  border-radius: 50%;
}

.comments li .comment strong.commenter-title {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  display: block;
  color: #8b8b8b;
}

.comments li .comment strong.commenter-title a {
  font-size: 20px;
  color: #2b343b;
  font-family: 'Playfair Display', sans-serif;
}

.comments li .comment span.comment-date {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 0px;
  text-transform: uppercase;
}

.comments li .comment span.comment-reply {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 7px;
}

.comments li .comment span.comment-reply a {
  transition: all 0.5s ease 0s;
  font-size: 11px;
  padding: 5px 15px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #4ba755;
  background: #4ba755;
  font-family: 'Karla', sans-serif;
}

.comments li .comment span.comment-reply a:hover {
  background: transparent;
  color: #4ba755;
}

.comments li .comment span.comment-reply a i {
  font-size: 11px;
}

.comments li .comment p:last-child {
  margin-bottom: 0px;
}

.comments li > ul {
  padding-bottom: 7px;
  padding-left: 30px;
  border-top: 1px solid #ece3e3;
}

.comments li .comment p {
  margin-top: 7px;
}

.blog-contact {
  padding: 0px;
}

.blog-contact .form-control {
  color: #555;
  background-color: #f3f3f3;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 1px;
  box-shadow: none;
  line-height: 2.429;
}

.blog-contact .form-group {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 0px;
}

.form-control:focus {
  border: 1px solid #fff;
  border: 1px solid #4ba755;
  box-shadow: none;
  border-left: 3px solid #4ba755;
}

.blog-contact textarea {
  line-height: 20px !important;
}

.blog-contact .contact-form #contact-form .con-field {
  line-height: 32px;
  margin-bottom: 20px;
}

.blog-contact .contact-form #contact-form textarea {
  padding-top: 10px;
}

/*-
    post-option 
  ------------------------------*/

.post-option {
  position: relative;
  padding: 25px 25px;
  margin-top: 50px;
  margin-bottom: 40px;
  overflow: hidden;
  border: 1px solid #ece3e3;
}

.post-option .arrow-icon {
  font-size: 18px;
  border: 1px solid #4ba755;
  border-radius: 50%;
  padding: 10px 12px;
  color: #4ba755;
  position: relative;
  top: 0px;
}

.post-option .arrow-icon:hover {
  color: #fff;
  background: #4ba755;
}

.post-option .prev-post,
.post-option .next-post {
  position: relative;
  font-size: 13px;
  color: #111;
  text-transform: uppercase;
}

.post-option .prev-post:hover,
.post-option .next-post:hover {
  color: #4ba755;
}

.post-option .middle-icon {
  position: absolute;
  left: 50%;
  margin-left: -15px;
}

.post-option .middle-icon a {
  position: relative;
  color: #4ba755;
  font-size: 27px;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  margin-top: 4px;
}

.post-option .middle-icon a:hover {
  color: #b9b3b3;
}

/*=====================================
    Single Index Page
  =====================================*/

.single-work-page {
  padding-top: 120px;
  padding-bottom: 90px;
}

.portfolioitem {
  margin-bottom: 37px;
}

.portfoliodesc {
  margin-bottom: 10px;
}

.portfolio_des {
  border-bottom: 1px solid #edecec;
  padding-bottom: 36px;
}

.port_contnt {
  display: inline-block;
  margin-top: 12px;
}

.sing_port_icon {
  display: block;
  width: 50px;
  float: left;
  margin-right: 10px;
}

.sing_port_icon i {
  color: #4ba755;
  font-size: 42px;
}

.port_contnt h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
}

.port_contnt p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.portfoliodesc h4,
.project-details h4 {
  font-size: 30px;
  text-transform: capitalize;
  letter-spacing: 0px;
  margin-top: 1px;
  margin-bottom: 20px;
  line-height: 33px;
}

.single-work-page .portfoliodesc .project-details h4 {
  margin-top: 40px;
}

.project-details {
  margin-top: 50px;
}

.portfoliodesc .project-details p {
  margin-top: 0px;
}

.sing_port_list {
  padding-top: 50px;
}

.sing_port_list ul {
  width: 50%;
  float: left;
  margin: 0;
}

.sing_port_list ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  padding-right: 20px;
}

.sing_port_list ul li:after {
  position: absolute;
  content: '\e907';
  font-family: 'custom-icons';
  left: 0;
  top: 0;
  color: #4ba755;
}

.related-portfolio {
  padding-top: 62px;
  border-top: 1px solid #efe4e4;
  margin-top: 74px;
}

/*=================================
     Blog Page  One ( 3 Column )
  ================================= */

.blog-page-one .blog_wrp,
.blog-page-two .blog_wrp {
  margin-bottom: 30px;
}

.blog_pg_one .blog_wrp img {
  width: 100%;
}

.blog-page-one .blog-section {
  padding-bottom: 120px;
}

/*=================================
     Blog Page  Two  
  ================================= */
.blog_page_tw {
  padding-bottom: 120px;
  background: #fff;
}

.blog_container .blog_date i {
  color: #4ba755;
}

.blog_container .blog_date span {
  margin-right: 20px;
}

.blog_container .blog_info h4 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 35px;
}

.blog_container .blog_info {
  padding: 25px 30px 30px;
  background: #fff;
  border: 1px solid #ece3e3;
}

.blog_container .more-link {
  margin-top: 20px;
}

/* =================================
      Shop page 
  ================================= */

.shop-page .product-section {
  padding-top: 120px;
}

/* =================================
      Product page 
  ================================= */
.product_pg_prod {
  padding-top: 120px;
  padding-bottom: 120px;
}

.product_count {
  float: left;
}

.product_ordering {
  float: right;
  margin-bottom: 50px;
}

.orderby {
  padding: 17px 30px;
  border: 2px solid #dbdcdd;
  color: #61657a;
  font-size: 15px;
  text-transform: uppercase;
}

/* .product_pg_prod .product_wrp {
  margin-bottom: 30px;
} */

.prodt_pagination li {
  display: inline-block;
  margin: 0 14px;
  cursor: pointer;
}

.prodt_pagination li span {
  border: 1px solid #ddcfcf;
  width: 40px;
  height: 40px;
  display: block;
  background: #4ba755;
  border-radius: 5px;
  line-height: 40px;
  margin: 0px 5px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}
.prodt_pagination li span.disable {
  background: #e4e4e4;
  pointer-events: none;
  color: #141414;
}

.prodt_pagination {
  text-align: center;
  display: block;
  font-size: 22px;
  font-weight: 500;
  position: relative;
  z-index: 3;
}

.prodt_pagination li span.current {
  background: #4ba755;
  color: #fff;
  border: 1px solid #4ba755;
}

.prodt_pagination li span:hover {
  background: #4ba755;
  color: #fff;
  border: 1px solid #4ba755;
}

/* =================================
      Single Product page 
  ================================= */
.shop-product-area {
  margin-bottom: 40px;
  padding-top: 120px;
}

.shop-product-area .slick-list {
  height: auto;
  padding: 0 !important;
}

.shop-product-area .single-thumbnail-small.slick-initialized.slick-slider {
  height: 138px !important;
  margin: 30px 0 0 !important;
  overflow: hidden;
  width: 100% !important;
}

.shop-product-area .custom-prev.slick-arrow {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #333;
  height: 30px;
  left: 0;
  position: absolute;
  top: 40%;
  width: 30px;
  z-index: 9999;
}

.shop-product-area .custom-prev::before {
  border: 1px solid #333;
  content: '';
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.custom-next.slick-arrow {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  bottom: 0;
  color: #333;
  height: 30px;
  position: absolute;
  right: 0;
  top: 40%;
  width: 30px;
}

.custom-next::before {
  border: 1px solid #333;
  content: '';
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.shop-product-area .single-thumb {
  cursor: pointer;
  margin-left: 0px;
  overflow: hidden;
  width: 139px !important;
  margin-right: -9px;
}

.shop-product-area .single-thumb img {
  padding-left: 20px;
}
.product-thumbnail .imgb {
  height: 370px;
  overflow: hidden;
}
.product-thumbnail .imgb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shop-product-area .slick-dots {
  display: flex !important;
  position: relative !important;
  bottom: 0px !important;
}
.shop-product-area .slick-dots li {
  width: 80px;
  margin-right: 10px !important;
  height: 80px;
  margin: 0px;
}
.shop-product-area .slick-dots li img {
  height: 80px;
  border: 1px solid #f5f0f0;
  padding: 2px;
  width: 80px;
  object-fit: cover;
}
.shop-product-area .slick-slide img {
  border: 1px solid #f5f0f0;
  padding: 2px;
  width: inherit;
  object-fit: cover;
}
.shop-product-area .slick-dots li:last-child {
  margin-right: 0px !important;
}

/*
      Single Product  Right Side
  ----------------------------------------*/

.allproduct-info {
  margin-left: 20px;
}
.allproduct-info .cart-plus-minus {
  margin-top: 30px;
}

.tittle_product {
  margin-bottom: 20px;
}

.tittle_product .next_prev {
  float: right;
}

.tittle_product .next_prev a span {
  padding: 4px;
  background: #ececec;
  font-size: 15px;
  color: #999999;
  margin-left: 5px;
}

.tittle_product .next_prev a span:hover {
  background: #4ba755;
  color: #fff;
}

.tittle_product {
  color: #2b343b;
  text-decoration: none;
  font-size: 26px;
  font-family: 'Playfair Display', sans-serif;
}

.detail-product-title {
  margin-bottom: 39px;
}

.detail-product-title > a {
  color: #333333;
  font-size: 18px;
  font-weight: 700;
}

.shop-product-area .n-amt {
  color: #2b343b;
  font-size: 20px;
  font-weight: 700;
  padding-right: 10px;
}

.shop-product-area del {
  color: #959595;
  font-size: 13px;
  font-weight: 700;
}

.rating2 {
  float: none;
  margin-top: -10px;
  margin-bottom: 20px;
}

.star.yes {
  color: #4ba755;
}
.star-rating ul .star {
  margin-right: 4px;
}
.detail-product-info .fa.fa-star {
  font-size: 12px;
}

.star-rating ul li {
  color: #959595;
  display: inline-block;
  margin-right: 4px;
}

.reviews {
  margin-left: 20px;
  margin-right: 10px !important;
  color: #999999;
}

.reviews a {
  color: #61657a;
}

.add-reviews {
  margin-left: 10px;
  color: #999999;
}

.add-reviews a {
  color: #61657a;
}

.shop-product-area .p-content {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 11px;
  margin-bottom:11px;
}

.shop-product-area .content {
  color: #989898;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

.shop-product-area .content span {
  color: #4ba755;
}

.shop-product-area .d-content {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 8px;
}

.d-content {
  left: 0;
  margin: auto;
  padding: 0;
  right: 0;
  width: 88%;
}

.cart-plus-minus {
  background: #4ba755;
  /* cursor: pointer; */
  height: 30px;
  width: 100%;
  position: relative;
  display: grid;
  align-items: center;
  border-radius: 6px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 110px;
}

.cart-plus-minus-box {
  border: none;
  display: block;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  color: #fff;
  padding: 0;
  background: transparent;
  min-height: 100%;
  text-align: center;
  pointer-events: none;
}

.qtybutton {
  color: #fff;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
}
.qtybutton.disable {
  opacity: 0.6;
  cursor: not-allowed;
}
.qtybutton span {
  text-align: center;
  display: block;
  display: block;
  width: 100%;
  height: 52px;
  line-height: 50px;
}

.inc.qtybutton {
  left: auto;
  right: 0;
}

.detail-choices {
  border-bottom: 1px solid #e9e9e9;
  float: left;
  padding-bottom: 13px;
  padding-top: 25px;
  width: 100%;
}

.detail-choices .choice-icon {
  margin-left: 0;
}

.shop-product-area .choice-icon {
  margin-bottom: 30px;
  margin-top: 30px;
}

.choice-icon {
  border: 2px solid #fff;
  display: inline-block;
  height: 45px;
  margin: auto;
  position: relative;
}

.shop-product-area .choice-icon li {
  float: left;
}

.shop-product-area li .adtocart {
  /* border: 2px solid #4ba755; */
  color: #fff;
  display: block;
  font-weight: 700;
  height: 45px;
  line-height: 45px;
  margin-right: 20px;
  padding: 0 41px;
  background: #4ba755;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s;
}

/* .shop-product-area li .adtocart:hover {
  background: transparent;
  color: #2b343b;
} */

.shop-product-area .heart {
  border: 2px solid #4ba755;
  color: #4ba755;
  float: left;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
}

.shop-product-area .heart:hover {
  background: #4ba755;
  color: #fff;
}

.shop-product-area .share-area {
  margin-top: 25px;
}

.shop-product-area .share-area li {
  display: inline-block;
}

.share-area i {
  background: #ececec;
  padding: 11px 12px;
  border-radius: 47%;
  color: #61657a;
  font-size: 15px;
  margin-right: 7px;
}

.share-area i:hover {
  background: #4ba755;
  color: #fff;
}

.categories-area {
  width: 100%;
  overflow: hidden;
  margin-bottom: -15px;
}

.categories-area p {
  font: 14px;
  color: #999999;
  float: left;
  padding-right: 10px;
}

.categories-area ul {
  list-style: none;
  margin-left: 10px;
  overflow: hidden;
}

.categories-area ul li {
  display: inline-block;
  margin-top: 1px;
  color: #61657a;
  margin-right: 4px;
}

.shop-product-area .category,
.tags {
  color: #989898;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 18px;
}

.tags {
  margin-top: -18px;
}

.tags span {
  color: #5d5d5d;
}

.shop-product-area .category span {
  color: #2b343b;
  font-weight: 700;
  margin-right: 8px;
}

/*
      product description 
  ----------------------------------------*/
.shop-tab-wrapper ul li {
  display: inline-block;
}
.shop-tab-wrapper {
  width: 100%;
  margin-top: 60px;
}
.shop-tab-wrapper ul li .shop-nav-tabs {
  padding-right: 50px;
  font-size: 22px;
  text-transform: capitalize;
  color: #2b343b;
  cursor: pointer;
}
.shop-tab-wrapper ul {
  border-bottom: 1px solid #eae2e2;
  padding-bottom: 10px;
}

.cust-reviews-area {
  padding-top: 60px;
}

.cust-reviews-area .nav-tabs {
  margin-bottom: 20px;
}

.cust-reviews-area .nav-tabs .active {
  border: 1px solid transparent;
  border-bottom: 1px solid #4ba755;
  color: #2b343b;
}

.cust-reviews-area .nav-tabs a {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 50px;
  font-size: 22px;
  text-transform: capitalize;
  color: #2b343b;
}

.cust-reviews-area .nav-tabs a:hover {
  border: 1px solid transparent;
  border-bottom: 1px solid #4ba755;
}

/*
      product Additional Information 
  ----------------------------------------*/

.prod_attributes {
  border: 0;
  border: 1px dotted rgba(0, 0, 0, 0.1);
  margin-bottom: 1.618em;
  width: 100%;
  margin-top: 25px;
}

.prod_attributes th {
  width: 190px;
  font-weight: 700;
  padding: 10px 20px 10px;
  color: #2b343b;
}

.prod_attributes td,
.prod_attributes th {
  line-height: 1.5;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  border-top: 0;
  margin: 0;
}

.prod_attributes tr:nth-child(2n) {
  background: #f9f9f9;
}

/*
      product Review 
  ----------------------------------------*/
.review_pic {
  position: absolute;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  left: 0px;
}
.review_wrp {
  padding-left: 125px;
}
.review_wrp span {
  color: #2b343b;
  font-size: 18px;
}
.revi_nam {
  color: #2b343b;
  font-size: 18px;
}
.review_wrp span.revw_dt {
  color: #61657a;
  font-size: 15px;
  margin-left: 5px;
}
.review_wrp span::before {
  float: right;
  color: #4ba755;
  margin-left: 3px;
}

.rat_des {
  margin-top: 15px;
}
.review_text {
  margin-top: 17px;
}

/*
    Related Product  
  ----------------------------------------*/
.related_product_section {
  background: #f1f6fa;
  padding-bottom: 120px;
}

/* =================================
      Checkout  page 
  ================================= */

.checkout_section {
  padding-top: 94px;
  padding-bottom: 100px;
}
.checkout_section .custom-title {
  /*! border-bottom: 1px solid #797979; */
  position: relative;
}
.checkout_section .custom-title::before {
  position: absolute;
  content: '';
  background-color: #aedf04;
}

.checkout_section .custom-title:before,
.checkout_section .custom-title:after,
.discount-coupon h4:before,
.discount-coupon h4:after,
.estimate-ship h4:before,
.estimate-ship h4:after,
.grand-total-area h4:before,
.grand-total-area h4:after,
.shop_cart_title h2:before,
.shop_cart_title h2:after {
  position: absolute;
  content: '';
  background-color: #4ba75596;
  width: 15px;
  height: 11px;
  bottom: 0px;
  left: 0%;
  margin-left: 10px;
  border-radius: 136px;
  margin-bottom: -15px;
}

.checkout_section .custom-title:after,
.discount-coupon h4:after,
.estimate-ship h4:after,
.grand-total-area h4:after,
.shop_cart_title h2:after {
  margin-left: 0;
  background-color: #4ba755;
}

.checkout_section .custom-title h3 {
  font-size: 24px;
}
.checkout_section .payment_mth {
  padding-top: 40px;
}
.checkout_section #payment label {
  border: 2px solid #ede6e4;
  font-weight: normal;
  font-size: 16px;
  text-transform: capitalize;
  padding: 25px 40px !important;
  color: #363535;
}
.checkout_section .checkbox {
  margin-bottom: 20px;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.checkout_section #payment .radio input[type='radio'],
.checkout_section #payment .radio-inline input[type='radio'],
.checkout_section #payment .checkbox input[type='checkbox'],
.checkout_section #payment .checkbox-inline input[type='checkbox'] {
  margin-left: -20px;
  margin-right: 15px;
  position: relative;
  color: #333;
}
.checkout_section .custom2 {
  display: block;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 15px;
  font-weight: 400;
}
.checkout_section .shopform .form-control {
  background-color: #fff;
  border: 2px solid #ede6e6;
  border-radius: 0;
  box-shadow: none;
  color: #333;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 12px;
  height: 44px;
  text-align: center;
  width: 263px;
  text-align: left;
  opacity: 0.5;
}
.checkout_section .shopform .form-control {
  width: 100%;
  margin: 2px 0 16px;
}
.checkout_section .shopform textarea.form-control {
  height: 180px;
  padding-top: 15px;
  padding-left: 15px;
  opacity: 0.5;
}
.checkout_section label {
  margin-bottom: 0px;
  display: block;
}

.coupon-area .row .col-md-12 {
  padding: 0px;
}
.coupon_show h3 {
  padding: 1.2em 2em 1.2em 3.5em !important;
  margin: 0 0 20px;
  position: relative;
  background-color: #f7f6f7;
  color: #61657a;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  border-top: 2px solid #4ba755;
  list-style: none !important;
  width: auto;
  word-wrap: break-word;
  font-family: 'Karla', sans-serif;
}
.coupon_show h3::before {
  color: #4ba755;
  content: '\f133';
  display: inline-block;
  font-family: fontawesome;
  left: 1.5em;
  position: absolute;
  top: 1.2em;
}
.coupon_show span {
  color: #222222;
  cursor: pointer;
}
.coupon-content {
  border: 1px solid #e5e5e5;
  display: none;
  margin-bottom: 20px;
  padding: 21px 25px 22px;
}

.coupon_info p {
  color: #222222;
  /*! font-family: Open Sans; */
  font-size: 14px;
  /*! font-weight: normal; */
  margin-bottom: 0;
}
.lost_pass {
  margin-top: 15px;
}
.coupon_info p.form-row input[type='checkbox'] {
  position: relative;
  top: 2px;
}
.lost_pass a {
  color: #222;
}
.input_login label {
  display: inline;
}

.coupon_info label input {
  position: relative;
  top: 2px;
}

p.checkout-coupon input[type='text'] {
  height: 36px;
  padding-left: 10px;
  width: 170px;
  float: left;
}
p.checkout-coupon input[type='submit'] {
  background: #2b343b;
  border: medium none;
  border-radius: 0;
  color: #fff;
  height: 36px;
  margin-left: 6px;
  padding: 5px 20px;
  transition: all 0.3s ease 0s;
  font-size: 13px;
}
/*==================================
     Cart Page   
  ==================================*/

.shop_cart {
  padding-top: 110px;
  padding-left: 40px;
  padding-right: 40px;
}
.shop_cart .manage_address {
  border: none;
  padding-left: 0;
}
.shop_cart_title h2 {
  margin-bottom: 40px;
  position: relative;
}
.shop_cart .table td,
.shop_cart .table th {
  vertical-align: middle;
}
.shop_cart table {
  margin-bottom: 50px;
  border: 1px solid transparent;
}

.shop_cart .table td img {
  width: 100px;
}
.shop_cart input[type='number'] {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  font-weight: normal;
  height: 40px;
  padding: 0 10px;
  transition: all 0.3s ease 0s;
  width: 60px;
}
.table-responsive tbody tr.table-info td {
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
}

.table > thead > tr > th {
  border-bottom: 1px solid #f8f8f8;
}
.table > tbody + tbody {
  border-top: 1px solid #f8f8f8;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #eeecec;
}
.shop_cart_tr .text-center {
  color: #2b343b;
  text-transform: uppercase;
  /*! letter-spacing: 0px; */
}
.table-info span {
  color: #333;
}
.edt > a {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}
.shop_cart_bottom {
  padding-top: 50px;
  padding-bottom: 120px;
}
.discount-coupon h4,
.estimate-ship h4,
.grand-total-area h4 {
  color: #282828;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 1px;
  margin-top: 0px;
  /*! border-bottom: 1px solid #070707; */
  padding-bottom: 0px;
  position: relative;
  margin-bottom: 30px;
}
.discount-coupon p,
.estimate-ship p {
  color: #8b8b8b;
  /*! font-size: 14px; */
  font-weight: 400;
  margin-bottom: 12px;
  padding-top: 4px;
}
.coupon {
  background: #f0f0f0 none repeat scroll 0 0;
  border: medium none;
  height: 35px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}
a.app-coupon,
a.get-quote {
  background: #2b343b;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 36%;
}

p.title-country,
p.title-state,
p.p-code {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  text-transform: capitalize;
}
.sort-by-country,
.sort-by-state,
.post-code {
  -moz-appearance: none;
  background: #f0f0f0;
  border: medium none;
  height: 37px;
  padding: 0 10px;
  width: 100%;
  color: #333;
}
p.title-country {
  padding-top: 3px;
  margin-bottom: 10px;
}
.estimate-ship .postal-code {
  margin-bottom: 20px;
}

.estimate-ship div {
  margin-bottom: 8px;
}
.grand-total-area {
  background: #f5f5f5 none repeat scroll 0 0;
  padding: 35px 35px;
  margin-bottom: 30px;
}
.grand-total-area h4 {
  margin-bottom: 34px;
}
.grand-total-area span {
  float: right;
}
.grand-total-area p {
  color: #333;
  display: block;
  font-size: 18px;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
}
.grand-total-area .grand-total {
  border-top: 1px solid #d2cccc;
  color: #333;
  font-size: 24px;
  padding-bottom: 0px;
  text-align: left;
  text-transform: capitalize;
  margin-top: 15px;
  padding-top: 15px;
}
.grand-total span.amt {
  color: #4ba755;
}
.pro-checkout {
  background: #000;
  color: #fff;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  margin-top: 30px;
  padding-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  width: 100%;
  transition: 0.3s;
}
.pro-checkout:hover {
  color: #fff;
}

/* =========== 404 page ================*/
.errorPage {
  padding: 205px 0px 200px;
  background-size: cover;
}
.errorPage h2 {
  font-size: 90px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 55px;
}
.errorPage span {
  font-size: 40px;
  color: #484747;
}
.errorPage p {
  margin-top: 10px;
}
.errorPage .more-link {
  margin-top: 14px;
}

/*  */

.offcanvasMobileMenu.active {
  transform: translateX(0);
}
.offcanvasMobileMenu {
  position: fixed;
  z-index: 999999999999;
  top: 0;
  right: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  padding-left: 60px;
  transition: 0.6s;
  transform: translateX(100%);
}

.offcanvasNavigation > ul li.menuItemHasChildren.active > .subMenu {
  visibility: visible;
  height: 100%;
  opacity: 1;
}
.offcanvasNavigation > ul li.menuItemHasChildren .subMenu {
  visibility: hidden;
  height: 0;
  transition: 0.3s;
  opacity: 0;
}
.offcanvasNavigation ul.subMenu {
  margin-left: 25px;
  padding-left: 0;
  transition: 0.3s;
}

.offcanvasNavigation ul li.menuItemHasChildren .menuExpand {
  line-height: 50px;
  position: absolute;
  top: -5px;
  right: auto;
  left: 95%;
  width: 30px;
  height: 50px;
  cursor: pointer;
  text-align: center;
}

.offcanvasNavigation ul li.menuItemHasChildren .menuExpand i {
  position: relative;
  display: block;
  width: 10px;
  margin-top: 25px;
  transition: all 0.25s ease-out;
  border-bottom: 1px solid;
}
.offcanvasNavigation ul li.menuItemHasChildren.active > .menuExpand i::before {
  transform: rotate(0);
}
.offcanvasNavigation ul li.menuItemHasChildren .menuExpand i::before {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  content: '';
  transform: rotate(90deg);
  border-bottom: 1px solid;
}

/* mobile menu */
.mobile-aside-button {
  font-size: 34px;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  color: #fff;
  border: none;
  background: none;
}
.mobile-aside-button svg {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  vertical-align: top;
}
.mobile-aside-button:focus {
  outline: none;
}
/* Mobile Menu */
/* offcanvas search */
.offcanvasMobileSearchArea {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  margin-left: 60px;
  padding: 7px;
  background-color: #e6e6e6;
}
.offcanvasMobileSearchArea input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 9px 29px;
  color: #222;
  border: none;
  background: #e6e6e6;
}
.offcanvasMobileSearchArea button {
  position: absolute;
  top: 50%;
  right: 20px;
  padding: 0;
  transform: translateY(-50%);
  color: #aaa;
  border: none;
  background: none;
}
.offcanvasMobileSearchArea button svg {
  font-size: 28px;
  line-height: 40px;
}
/* offcanvas mobile menu */
.headerMobileNavigation {
  padding: 20px 0;
}
.headerMobileNavigation .mobileNavigation .headerCartIcon a span {
  left: 50%;
}
.mobileButtonWrapper button {
  font-size: 40px;
  padding: 0;
  color: #fff;
  border: none;
  background: none;
}
.offcanvasWidgetArea {
  margin-top: auto;
  margin-bottom: 35px;
}
.offCanvasContactWidget {
  margin-bottom: 20px;
}
.offCanvasContactWidget .headerContactInfo {
  flex-basis: 33.33%;
}
.offCanvasContactWidget .headerContactInfoList {
  padding-left: 0;
}
.offCanvasContactWidget .headerContactInfoList li {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 10px;
}

.offCanvasContactWidget .headerContactInfoList li a {
  color: #ca3c08;
}
.offCanvasContactWidget .headerContactInfoList li a:hover {
  color: #ca3c08;
}
.offCanvasContactWidget .headerContactInfoList li:last-child {
  margin-right: 0;
}
.offcanvasMobileMenu {
  position: fixed;
  z-index: 999999999999;
  top: 0;
  right: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  padding-left: 60px;
  transition: 0.6s;
  transform: translateX(100%);
}
.offcanvasMobileMenu.active {
  transform: translateX(0);
}
.offcanvasMobileMenu.inactive {
  transform: translateX(calc(100% + 60px));
}
.offcanvasMenuClose {
  font-size: 30px;
  line-height: 1;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 60px;
  height: 58px;
  padding: 0;
  text-align: center;
  color: #fff;
  border: none;
  background: #343538;
}
.offcanvasMenuClose:hover,
.offcanvasMenuClose:focus {
  color: #fff;
  outline: none;
}
.offcanvasMenuClose i {
  font-size: 24px;
  transition: 0.3s;
  transform: rotate(0);
  position: relative;
  top: -1px;
}
.offcanvasMenuClose:hover i {
  transform: rotate(-90deg);
}
.offcanvasWrapper {
  overflow: auto;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
}
.offcanvasInnerContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 85px 20px 0;
}
.offCanvasWidgetSocial a {
  font-size: 17px;
  margin: 0 10px;
}
.offCanvasWidgetSocial a:first-child {
  margin-left: 0;
}
/* offcanvas settings */
.offcanvasSettings .offcanvasNavigation > ul > li > a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0;
}
.offcanvasSettings
  .offcanvasNavigation
  > ul
  > li.menuItemHaschildren
  .menuExpand {
  top: -15px;
  height: 30px;
  margin-top: 0;
}
.offcanvasSettings .offcanvasNavigation ul.subMenu > li > a {
  padding: 5px 0;
}
.transparentBar {
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
  width: 100%;
}
.transparentBar .headerTop {
  display: none !important;
}
.offcanvasNavigation {
  margin-bottom: 50px;
}
.offcanvasNavigation > ul {
  padding-left: 0;
}
.offcanvasNavigation > ul li.menuItemHasChildren .subMenu {
  visibility: hidden;
  height: 0;
  transition: 0.3s;
  opacity: 0;
}
.offcanvasNavigation > ul li.menuItemHasChildren.active > .subMenu {
  visibility: visible;
  height: 100%;
  opacity: 1;
}
.offcanvasNavigation > ul li > a {
  font-weight: 500;
  display: block;
  padding: 6px 0;
  color: #222;
}
.offcanvasNavigation > ul li > a:hover {
  color: #111;
}
.offcanvasNavigation ul.subMenu {
  margin-left: 25px;
  padding-left: 0;
  transition: 0.3s;
}
.offcanvasNavigation ul.subMenu > li > a {
  font-weight: 500;
  padding: 6px 0;
  color: #333;
}
.offcanvasNavigation ul.subMenu > li > a:hover {
  color: #111;
}
.offcanvasNavigation ul li a {
  text-transform: uppercase;
}
.offcanvasNavigation ul li.menuItemHasChildren {
  position: relative;
  display: block;
}
.offcanvasNavigation ul li.menuItemHasChildren a {
  display: block;
}
.offcanvasNavigation ul li.menuItemHasChildren.active > .menuExpand i:before {
  transform: rotate(0);
}
.offcanvasNavigation ul li.menuItemHasChildren .menuExpand {
  line-height: 50px;
  position: absolute;
  top: -5px;
  right: auto;
  left: 95%;
  width: 30px;
  height: 50px;
  cursor: pointer;
  text-align: center;
}
.offcanvasNavigation ul li.menuItemHasChildren .menuExpand i {
  position: relative;
  display: block;
  width: 10px;
  margin-top: 28px;
  transition: all 250ms ease-out;
  border-bottom: 1px solid;
}
.offcanvasNavigation ul li.menuItemHasChildren .menuExpand i:before {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  content: '';
  transform: rotate(90deg);
  border-bottom: 1px solid;
}
/* login css  */

#login-intro {
  padding: 56px 20px 0;
  text-align: center;
}
.logo_login {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  width: 100% !important;
}
.logo img {
  height: 100%;
}
.title {
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  color: #000;
}
.description {
  padding-top: 16px;
  font-size: 14px;
  max-width: 600px;
  color: #000;
}

.register_form,
.login {
  padding: 50px 30px;
  min-height: 450px;
}
.Typewriter__wrapper {
  font-size: 25px;
  color: #1f83ad;
}
.Typewriter__cursor {
  font-size: 25px;
}
#login-form-wrapper {
  box-shadow: 1px 3px 13px 13px #ededed;
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  height: 432px;
  align-items: center;
  padding: 20px 40px;
  display: flex;
}
#register-form-wrapper {
  box-shadow: 1px 3px 13px 13px #ededed;
  border-radius: 10px;
  width: 100%;
  overflow: auto;
  align-items: center;
  padding: 20px 40px;
  display: flex;
}
#register-form {
  /* padding: 0.2rem 1rem; */
  background-color: #fff;
  /* margin: 20px 20px 0; */
  width: 100%;
}
#login-form {
  /* padding: 0.2rem 1rem; */
  background-color: #fff;
  /* margin: 20px 20px 0; */
  width: 100%;
}
#register-form-wrapper #register-form .title {
  font-size: 21px;
  font-weight: 500;
  text-align-last: center;
}
#login-form-wrapper #login-form .title {
  font-size: 21px;
  font-weight: 500;
  text-align-last: center;
}
.title {
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
  color: #000;
}
.register {
  text-align: center;
  padding-top: 10px;
}
.sub-title {
  color: #000 !important;
  font-weight: 300;
  font-size: x-large;
  line-height: 1;
}
#imagePreview {
  margin-top: 20px;
  width: 50%;
  border: 3px solid #000;
}
input,
textarea {
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #888;
  min-height: 45px;
  width: 100%;
}
textarea {
  padding-top: 10px;
  padding-bottom: 10px;
}
.input_fields {
  display: grid;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.user_dashboard_title {
  padding: 25px 30px;
  border-radius: 15px;
  align-items: center;
  box-shadow: 0 0 40px rgb(0 0 0 / 10%);
  margin-top: 30px;
}
.user_dashboard_title .left p {
  font-size: 18px;
  margin-right: 30px;
  font-weight: bold;
}
.user_dashboard_title .left p strong {
  font-weight: 500;
  padding: 0 10px;
}
.user_dashboard_title .acount_active_status {
  background: #37ad1f;
  color: #fff;
  border-radius: 20px;
  font-weight: 500;
  padding: 10px 30px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.user_dashboard_title .acount_inactive_status {
  background: #ca3c08;
  color: #fff;
  border-radius: 20px;
  font-weight: 500;
  padding: 10px 29px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 767px) {
  .user_dashboard_title .left p strong {
    padding: 10px 0;
    display: block;
  }
}

.shop-tab-wrapper ul li {
  display: inline-block;
}
.shop-tab-wrapper {
  width: 100%;
  margin-top: 60px;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #ededed;
  margin: 0 0 10px;
  padding: 0;
}
.react-tabs__tab--selected span {
  color: #4ba755 !important;
}
.tab_selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
  border: 1px solid #aaa;
  padding: 3px 0px 3px 12px !important;
}
.react-tabs__tab {
  display: inline-block;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  margin: 0px 8px;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #ededed;
}

.tab_selected .shop-nav-tabs {
  color: #fff !important;
}
.tab_selected {
  background: #1f83ad;
  border: 1px solid #fff;
  padding: 0 !important;
  padding: initial !important;
}
@keyframes fadeInDown {
  0% {
    transform: translate3d(0, -100px, 0);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}
.info_box {
  box-shadow: 0 0 40px rgb(0 0 0 / 10%);
  padding: 25px 30px;
  border-radius: 15px;
  margin-bottom: 50px;
  font-size: 18px;
}
.info_box p {
  margin: auto;
  padding-bottom: 25px;
  letter-spacing: 0.003rem;
}
.info_box .bottom_options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.info_box p {
  margin: 0;
  padding-bottom: 25px;
  letter-spacing: 0.003rem;
  display: flex;
  grid-column-gap: 30px;
  column-gap: 30px;
  max-width: 500px;
}
.info_box p strong {
  min-width: 100px;
}

.info_box .bottom_options {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 40px;
}
.bottom_options .account {
  background: #50ae2b;
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
}

.user_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}
.grand-total-area p {
  max-width: 100%;
  justify-content: space-between;
}
.shop_cart_bottom {
  padding-bottom: 30px;
}
.loader_outer {
  text-align: center;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loader_outer img {
  width: 90px;
  height: auto;
}
.nodata {
  text-align: center;
  text-transform: capitalize;
  font-size: 28px;
}
.nodata h4 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 1.2px;
  padding: 10px 0;
}

.disable {
  pointer-events: none;
}
.dashboard {
  background: aliceblue;
  overflow: auto;
}
.dashboard .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
}
.dashboard .top .detail_box {
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.dashboard .top .detail_box img {
  border-radius: 100%;
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid #000;
}
.dashboard .top .detail_box p {
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}
.dashboard .top .other_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  font-size: 14px;
  font-weight: bold;
}
.detail_box .edit_profile_btn:hover {
  transform: none !important;
}
.edit_profile_btn {
  border: none;
  background: #2b343b;
  outline: none;
  border-radius: 5px;
  padding: 7px 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
}
.detail_box button {
  background: transparent;
  color: #4ca853;
  padding: 0;
  font-weight: bold;
  text-decoration: underline;
}
.dashboard .tab_box {
  background: #fff;
  padding: 40px;
  border-radius: 6px;
  margin-bottom: 40px;
}
.tab_box li {
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  border-bottom: 1px solid #4ca853;
  cursor: pointer;
  margin-left: -15px;
  transition: 0.3s;
  padding: 10px 15px;
}
.tab_box li:hover,
.tab_box li.active {
  background-color: aliceblue;
  border-color: transparent;
}
.past_order {
  border-left: 1px solid #4ca853;
  padding: 0 30px;
}
.dashboard .order_box {
  border: 1px solid #4ca853;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  grid-gap: 15px;
  gap: 15px;
  position: relative;
  flex-wrap: wrap;
}
.dashboard .order_box:last-child {
  margin-bottom: 0;
}
.dashboard .order_box .left {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.dashboard .order_box img {
  width: 100px;
  height: auto;
}
.dashboard .order_box .txtb {
  font-size: 14px;
  flex: 1 1;
}
.dashboard .order_box p {
  font-weight: bold;
  font-size: 18px;
}
.dashboard .order_box span {
  display: block;
  margin-right: 15px;
}
.get_details {
  background: #4ca853;
  border: none;
  color: #fff;
  padding: 3px;
  width: 110px;
  border-radius: 4px;
  margin-top: 10px;
  transition: 0.3s;
}
.refresh_btn {
  background: #ffbe16;
  border: none;
  color: #fff;
  padding: 3px;
  width: 110px;
  border-radius: 4px;
  margin-top: 10px;
  transition: 0.3s;
}
.download_invoice {
  border: 1px solid #ff6e00;
  color: #ff6e00;

  padding: 3px;
  width: 110px;
  border-radius: 4px;
  margin-top: 10px;
  transition: 0.3s;
  background: transparent;
  width: -webkit-max-content;
  width: max-content;
  padding: 0px 16px;
}
.download_invoice.cancel {
  border: 1px solid #cb0021;
  color: #cb0021;
}

.manage_address {
  border-left: 1px solid #4ca853;
  padding-left: 30px;
}
.address_wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  align-items: flex-start;
  flex-direction: column;
}
.address_box {
  border: 1px solid #4ca853;
  padding: 20px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 0;
  width: 280px;
  position: relative;
}
.address_box .address {
  grid-gap: 20px;
  gap: 20px;
}
.address_box i {
  margin-top: 5px;
  font-size: 25px;
  color: #4ca853;
}
.address_box.add_more {
  max-width: 270px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.address_box.add_more h3 {
  font-size: 20px;
}
.address h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.address p {
  margin: 0;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.03rem;
  padding-bottom: 10px;
}
.address .options {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.address button {
  background: transparent;
  border: none;
  color: #4ca853;
  font-size: 17px;
  font-weight: 700;
  transition: 0.3s;
}
.sidebar_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  top: 0;
  left: 0;
}
.sidebar {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 35%;
  background: #fff;
  height: 100vh;
  overflow: auto;
  min-width: 300px;
  padding: 40px;
  box-shadow: -3px 10px 20px 11px #0000001f;
  transition: 0.5s;
  right: -100%;
}
.sidebar_close {
  position: absolute;
  left: 40px;
  font-size: 20px;
  color: #000;
  top: 46px;
  cursor: pointer;
}
.sidebar h3 {
  padding-left: 30px;
}
.sidebar .update_btn {
  background: #4ca853;
  border: none;
  margin: 20px 0;
  display: block;
  width: 100%;
  color: #fff;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
}
.sidebar .item_count {
  border-bottom: 1px solid #4ca853;
}
.sidebar .item_box {
  border-bottom: 1px solid #4ca853;
  padding: 10px 0;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.item_box .item_details {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.item_details img {
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.item_details .txtb {
  font-size: 16px;
  line-height: 1.2;
  text-align: left;
}
.item_details .txtb p {
  margin: 0;
  font-weight: bold;
  padding-bottom: 10px;
}
.item_details .price_tag {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.sidebar .all-products {
  display: block;
  padding-left: 0;
}
.sidebar .all-products h3 {
  padding: 20px 0;
}
.sidebar .bill {
  padding: 20px 0;
  font-weight: bold;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.sidebar .bill h5 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
.no_items {
  /* height: calc(100vh - 80px); */
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  background: #fff;
}
.no_items_bg {
  width: 271px;
  height: 256px;
  /* background-image: url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/2xempty_cart_yfxml0); */
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}
.no_items_title {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 600;
  color: #535665;
}
.no_items_sub_title {
  margin-top: 8px;
  color: #7e808c;
}
.no_items_btn {
  margin-top: 30px;
  padding: 11px 20px;
  text-transform: uppercase;
  background-color: #4ba755;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: 15px;
  text-align: center;
  transform: translateY(0);
  transition: transform 0.8s cubic-bezier(0.2, 1, 0.2, 1);
}
.no_items_btn:hover {
  position: relative;
  box-shadow: 0 4px 14px #d4d5d9;
  color: white;
}
.address_listing {
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.profile_upload {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #2b343b;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 1px 6px 39px 5px #b1acac54;
}
.profile_upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.order_status {
  position: absolute;
  background: #007bff;
  color: #fff;
  padding: 3px 15px;
  border-radius: 0 4px 0px 0;
  right: -15px;
  top: -1px;
}
.order_status.recieved {
  background: #28a745;
}
.order_status.packed,
.order_status.on-the-way,
.order_status.hand-over {
  background: #17a2b8;
}
.order_status.delivered {
  background: #28a745;
}
.order_status.cancelled {
  background: #dc3545;
}
.order_status.return {
  background: #ffc107;
}
.one_box {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.white_load {
  width: 20px !important;
  height: auto;
  filter: brightness(0.5);
}
.order_paginate {
  text-align: right;
}
.order_paginate li {
  margin: 0 15px;
  display: inline-flex;
  text-align: center;
  padding-left: 0;
  border: none !important;
  padding: 0px;
}
.all-products .slick-next:before,
.slick-prev:before {
  color: #00aa49;
}
.all-products {
  margin-bottom: 40px;
}
.related_products {
  display: flex;
  overflow-x: scroll;
}
.related_products::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/* .single-item-detail .imgb{
  padding: 0 40px;
}
.single-item-detail .slick-next, .single-item-detail .slick-prev{
  background: black;
  z-index: 3;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.single-item-detail .slick-prev{
  left: 0;
}
.single-item-detail .slick-next{
  right: 0;
}
.single-item-detail .slick-prev::before , .single-item-detail .slick-next::before{
  font-family: 'FontAwesome';
}
.single-item-detail .slick-next::before{
  content: "\f178" !important;
}
.single-item-detail .slick-prev::before{
  content: "\f177" !important;
}

.single-item-detail .slick-next:focus,
.single-item-detail .slick-next:hover, 
.single-item-detail .slick-prev:focus, 
.single-item-detail .slick-prev:hover{
  background: black;
}
.single-item-detail img{
  object-fit: cover;
} */
.info_box_outer {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.info_box_outer span {
  background: red;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.info_box_outer p {
  position: absolute;
  width: 150px;
  background: red;
  color: #fff;
  padding: 6px;
  border-radius: 5px;
  top: 0;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 600;
  opacity: 0;
  z-index: -1;
  transition: 0.3s;
}
.info_box_outer:hover p {
  opacity: 1;
  z-index: 1;
}
.img-magnifier img {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}
.slide-img img {
  height: 320px;
  width: 100% !important;
  object-fit: contain;
  min-width: 100%;
}

.get_details:hover,
.download_invoice:hover,
.refresh_btn:hover,
.edit_profile_btn:hover,
.header_cart_box:hover,
.update_btn:hover,
.shop-product-area li .adtocart:hover,
.address button:hover,
.pro-checkout:hover,
.submit-contact:hover {
  opacity: 0.8;
  transform: translateY(-2px);
}
.order-heading-container {
  flex-direction: column;
  margin-bottom: 13px;
  text-align: center;
  display: flex;
  align-items: center;
}
.cart-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  color: inherit;
}
label {
  margin-top: 0.5rem;
}
.logo_login img{
    height: 209px;
    width: 280px;
  }
.sidebar .item_box .item_details p{
    width: 100%;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
}

/*          Responsive styles
================================================== */

@media only screen and (min-width: 1678px) and (max-width: 1923px) {
}

@media only screen and (min-width: 1390px) and (max-width: 1677px) {
}
/*                     Tablet Landscape
=======================================================================================*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	/*--------------------------------------
   Header And SLider
----------------------------------------*/
	.header_top_right {
		width: 45.5%;
	}

	.navigation .col-md-8 {
		flex: 0 0 63.667%;
		max-width: 63.667%;
	}

	/* Slider  */
	.slider_home .swiper-pagination {
		position: relative;
		justify-content: center;
		padding-right: 15px;
	}
	.slider_home .swiper-pagination-bullet {
		position: relative;
		margin-bottom: 0px !important;
		top: -25px;
	}
	.slider_home .swiper-pagination-bullet:nth-child(1) {
		margin-right: 6px;
	}
	/* End: Slider  */

	.subfooter {
		margin-top: 78px;
	}

	.single_slider .col-sm-12 {
		flex: 0 0 59%;
		max-width: 59%;
	}

	.single_slider.slide_bg_2 .col-lg-6 {
		margin-left: 41%;
	}

	.about-section,
	.organic_farming_content {
		padding-bottom: 90px;
	}

	.funfact_wapr.row {
		width: 88%;
	}

	.testi-section {
		padding-bottom: 178px;
	}

	.post-date {
		font-size: 13px;
	}

	/*  Single Page
----------------------------------------*/
	.contact_pg_address {
		padding: 40px 35px 36px;
	}

	.blog_container .blog-area {
		flex: 0 0 72%;
		max-width: 72%;
	}

	.blog_container .widget-area {
		flex: 0 0 28%;
		max-width: 28%;
	}

	.recent-title {
		width: 51%;
	}
}

/* For Header */
@media (max-width: 991px) {
	.header_topbar .more-link {
		display: none;
	}
	.header_cart ul {
		margin-right: 8px;
		margin-left: 0;
		padding-left: 10px;
	}
	.search_icon_inr {
		margin-top: 29px;
	}
	.meun_wrp {
		display: none;
	}
	.header-btn-wrapper i {
		color: #333;
		font-size: 27px;
		position: relative;
		top: 22px;
	}
	.header_topbar {
		background: #2b343b;
		display: none;
	}
	.offcanvasWrapper .header_top_right {
		margin-top: auto;
		margin-bottom: 15px;
	}
	.offcanvasWrapper .header_top_right ul li {
		color: #333;
		display: block;
		margin-bottom: 10px;
		font-size: 16px;
	}
	.offcanvasWrapper .header_top_right ul li i {
		font-size: 16px;
	}
	.offcanvasWrapper .offcanvasInnerContent .header_socil li {
		border-right: none;
		padding-right: 5px;
		color: #fff;
		margin-right: 0px;
	}
	.offcanvasWrapper .header_socil li a {
		color: #4ba755;
		margin-left: 0px;
		margin-right: 10px;
		font-size: 20px;
	}
	.offcanvasWrapper .header_socil {
		margin-left: 0px;
		margin-bottom: 35px;
	}

	/* Slider  */
	.slider_home .swiper-pagination {
		position: relative;
		justify-content: center;
		padding-right: 15px;
	}
	.slider_home .swiper-pagination-bullet {
		position: relative;
		margin-bottom: 0px !important;
		top: -25px;
	}
	.slider_home .swiper-pagination-bullet:nth-child(1) {
		margin-right: 6px;
	}
	/* End: Slider  */
	.past_order , .manage_address{
		padding: 0;
		padding-top: 30px;
		border-left: none;
	}
	.has-sub{
		font-weight: 500;
		display: block;
		padding: 6px 0;
		color: #222;
		text-transform: uppercase;
	}
	.has-sub ul{
		display: none;
	}
	.has-sub ul.active{
		display: block;
	}
}

/*                      Tablet Portrait
=======================================================================================*/

@media (min-width: 768px) and (max-width: 991px) {
	/*--------------------------------------
   Header And SLider
----------------------------------------*/

	.navigation .container {
		position: relative;
	}

	.navigation .logo {
		margin-top: -78px;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.navigation .logo img {
		margin-top: 105px;
	}

	.single_slider .col-lg-6.col-sm-12 {
		flex: 0 0 79%;
		max-width: 79%;
	}
	.single_slider.slide_bg_2 .col-lg-6 {
		margin-left: 20.5%;
	}
	.about-section,
	.organic_farming_content {
		padding-bottom: 40px;
	}

	.about-section .col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 50px;
	}

	.service-item:hover .serv_link {
		left: 55%;
	}

	.video_wrp {
		width: 100%;
	}

	.funfact_wapr.row {
		width: 100%;
	}

	.facts_wrapper h5 {
		line-height: 20px;
		margin-top: 15px;
	}

	.angle_icon {
		margin-right: -49px;
	}

	.angle_icon img {
		width: 70px;
	}

	.whychose_bg {
		display: none;
	}

	.whychose_wrp {
		margin-right: 15px;
		margin-left: 15px;
		margin-top: -54px;
	}

	.product-section .col-sm-12 {
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: 30px;
	}
	.col-sm-12.product_orderby {
		max-width: 100%;
		flex: 100% 1;
		margin-bottom: 0px;
	}
	.product-section {
		margin-bottom: -30px;
	}

	.pricing-section .col-sm-12 {
		padding-right: 0px;
	}

	.project-section .col-md-12 {
		padding-left: 5px;
		padding-right: 5px;
	}
	.team-section {
		background-position: center;
	}
	.height_custom{
		height: 870px;
	}

	.testi-section {
		padding-bottom: 163px;
	}

	.testi_sing_img img:nth-child(1) {
		right: -30px;
		margin-top: -25px;
		width: 260px;
	}

	.testi_sing_img {
		margin-top: 40px;
	}

	.blog-section .col-sm-12 {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.blog-section .col-sm-12 .col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.blog-section .col-sm-12 .col-sm-12:last-child {
		display: none;
	}

	.blog_wrp_list {
		margin-bottom: 15px;
	}

	.blog-section {
		padding-bottom: 105px;
	}

	.contact_bg {
		display: none;
	}

	.contact-form {
		padding-right: 0px;
	}

	.footer-section .col-sm-12 {
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: 14px;
	}

	.subfooter {
		margin-top: 70px;
	}
	/*------------------------------------------------------------------Tab----------------------
   Single Page
----------------------------------------*/
	header.blog-banner {
		background-position: right;
	}

	.contact_pg_contact .inner-contact {
		flex: 0 0 52%;
		max-width: 52%;
	}

	.contact_pg_contact .col-md-4.col-sm-12 {
		flex: 0 0 48%;
		max-width: 48%;
	}

	.project_pg_proj .col-md-12 {
		margin-bottom: 10px;
	}

	.single_service .col-md-8.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.single_service .col-md-4.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.single_service_cat {
		margin-top: 30px;
	}
	.product_pg_prod .col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 0px;
	}

	.product_pg_prod .col-md-3.col-sm-12 {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.shop-product-area .single-thumb {
		width: 128px !important;
	}

	.cust-reviews-area .nav-tabs a {
		margin-right: 40px;
	}

	.blog_container .blog-area {
		flex: 0 0 65%;
		max-width: 65%;
	}

	.blog_container .widget-area {
		flex: 0 0 35%;
		max-width: 35%;
	}

	.blog_container .widget {
		padding: 30px 22px;
	}

	.blog_container .widget-search {
		padding: 5px 10px;
	}

	.recent-title {
		width: 51%;
	}

	.blog_container .blog_info h4 {
		font-size: 25px;
	}

	.post_tags {
		width: 100%;
	}

	.post_share .list-icons {
		float: left;
		margin-top: 15px;
		margin-left: -10px;
	}

	.post_share.footer_socil {
		width: 100%;
	}

	/* Cart page */
	.shop_cart_bottom .col-sm-12:nth-child(2) {
		margin-bottom: 50px;
	}
	.shop_cart_bottom .col-sm-12:nth-child(1) {
		margin-bottom: 45px;
		margin-top: -56px;
	}
	.shop-page .product-section .col-sm-12 {
		margin-bottom: 0px;
	}
}
/*                          Phone
=======================================================================================*/

@media only screen and (max-width: 767px) {
	/*--------------------------------------
   Header And SLider
----------------------------------------*/
	.header_topbar {
		display: none;
	}
	.allproduct-info{
		margin-top: 35px;
	}
	.navigation .container {
		position: relative;
	}

	.navigation .logo {
		margin-top: -78px;
		flex: 0 0 30%;
		max-width: 30%;
	}
	.navigation .col-md-8 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	#navigation.small-screen > ul {
		margin-top: 10px;
		border-left: 1px solid rgba(120, 120, 120, 0.2);
		border-right: 1px solid rgba(120, 120, 120, 0.2);
		border-bottom: 1px solid rgba(120, 120, 120, 0.2);
		margin-bottom: 15px;
		margin-left: 17px;
	}

	#navigation.small-screen #menu-button {
		padding: 38px;
		margin-top: 26px;
		margin-right: 84px;
	}

	#navigation > ul > li > a {
		padding: 16px 20px;
	}

	#navigation li:hover > ul {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	#navigation > ul > li.has-sub > a {
		padding: 17px 20px;
	}

	/*---- Slider----- */
	.single_slider .col-lg-7.col-xs-12 {
		width: 100%;
	}

	.single_slider {
		height: 800px;
	}
	.single_slider_about {
		height: 580px;
	}
	.single_slider .container {
		margin-top: -20px;
	}

	.single_slider.slide_bg_2 .col-lg-6 {
		margin-left: 0px;
	}

	.slider_home h2 {
		font-size: 45px;
		line-height: 50px;
	}

	.slider_home h5 {
		font-size: 16px;
	}

	.slider_home.owl-theme .owl-dots .owl-dot {
		display: none;
	}

	.slider_home .owl-nav {
		margin-top: -60px;
		text-align: center;
	}

	.slider_home .owl-nav div.owl-next,
	.slider_home .owl-nav div {
		left: auto !important;
		margin: 0px;
		right: auto !important;
	}

	.slider_home .owl-nav div:hover {
		color: #fff;
	}

	.slider_home .owl-nav div {
		display: inline-block !important;
		position: relative !important;
	}

	/*--------------------------------------
   about section
----------------------------------------*/

	.base-header {
		width: 100%;
	}

	.base-header h3 {
		line-height: 40px;
		font-size: 38px;
		letter-spacing: 1px;
	}

	.about-section .col-sm-12 {
		margin-bottom: 50px;
	}

	.about-section .col-sm-12 {
		margin-bottom: 50px;
	}

	.about-section {
		padding-bottom: 40px;
		padding-top: 140px;
	}

	.service-item:hover .serv_link {
		left: 100%;
		margin-left: -66px;
	}

	.img_serv img {
		width: 100%;
	}

	.service-item {
		margin-bottom: 50px;
	}

	.service-section {
		padding-bottom: 70px;
	}

	.video-section {
		background-position: center;
		background-size: cover;
		padding-bottom: 85px;
	}

	.video_wrp {
		width: 100%;
	}

	.funfact_wapr .icon-lay i {
		position: inherit;
		margin-left: 0px;
		padding: 15px 17px;
	}

	.facts_wrapper h4 {
		margin-top: 20px;
	}

	.facts_wrapper {
		text-align: center;
		padding-left: 0px;
		padding-bottom: 30px;
	}

	.process-item {
		margin-bottom: 40px;
	}

	.angle_icon {
		display: none;
	}

	.whychose_bg {
		background-position: left center;
		height: 600px;
		margin-left: 15px;
		margin-right: 15px;
		margin-top: -80px;
	}

	.whychose_wrp {
		margin-right: 15px;
		margin-left: 15px;
		margin-top: -54px;
		padding-left: 30px;
		padding-right: 30px;
		background-size: cover;
	}

	.special_ser_item {
		padding-left: 60px;
	}

	.product-section .col-sm-12,
	.pricing-section .col-sm-12,
	.team-section .col-sm-12 {
		margin-bottom: 30px;
	}

	.product-section,
	.pricing-section,
	.team-section {
		margin-bottom: -30px;
	}
	.col-sm-12.product_orderby {
		max-width: 100%;
		flex: 100% 1;
		margin-bottom: 0px;
	}
	.project-section .col-md-6 {
		width: 70%;
	}

	.project-section .col-md-6.arrows-slick {
		width: 30%;
	}

	.project-section .col-md-12 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.team-section {
		padding-bottom: 120px;
		background-size: cover;
	}

	.testi-section {
		padding-bottom: 80px;
	}

	.testi_wrp {
		padding: 15px 0px 40px 15px;
		flex-direction: column;
	}
	.testi_wrp img {
		display: block;
		margin: 0 auto;
	}

	.testi_sing_img {
/* 		display: none; */
	}

	.testi-section .owl-theme .owl-controls .owl-nav div {
		margin-left: -27px;
	}

	.testi-section .base-header {
		margin-bottom: 0px;
	}

	.blog_img img {
		max-height: inherit;
		width: 100%;
	}

	.blog_wrp {
		overflow: hidden;
		margin-bottom: 30px;
	}

	.contact_bg {
		display: none;
	}

	.contact-form {
		padding-right: 0px;
	}

	.contact_wrp {
		padding: 60px 30px;
	}
	/*--------------------------------------
   footer Section
----------------------------------------*/
	.footer-section .container {
		margin-bottom: 65px;
	}

	.scrollup {
		margin-bottom: -102px;
		right: 50%;
		margin-right: -18px;
	}

	.footer-section .widget h5 {
		margin-bottom: 27px;
	}

	.widget.quick_lnk {
		margin-bottom: 35px;
	}

	.widget .recent-gallery {
		margin-bottom: 30px;
		overflow: hidden;
	}

	.footer_recent_blog {
		margin-bottom: 30px;
	}

	.subfooter p {
		padding: 0px 15px;
	}
	/*--------------------------------------
   Single Page
----------------------------------------------    ph-----------------------------------*/
	header.blog-banner {
		background-position: right;
		background-size: 100% 152px;
	}

	.contact_pg_address {
		margin-top: 50px;
		margin-bottom: 20px;
	}

	.contact_pg_contact .contact-form {
		padding-right: 0px;
	}
	.ab_pg_process {
		padding-bottom: 80px;
	}
	.project_pg_proj .col-md-12 {
		margin-bottom: 10px;
	}

	.product_pg_prod {
		padding-bottom: 120px;
		margin-bottom: 0px;
	}

	.product_pg_prod .col-sm-12 {
		margin-bottom: 0px;
	}

	.product_ordering {
		width: 100%;
		float: none;
	}

	.product_count {
		float: none;
	}
	.cust-reviews-area{
		padding-top:0px;
	}

	.cust-reviews-area .nav-tabs a {
		margin-right: 0px;
		margin-top: 1px;
		display: table-row;
		width: 100%;
		border: 1px solid #ece6e6;
		padding: 10px 15px;
	}
	.cust-reviews-area .nav-tabs .active {
		border: 1px solid #ece6e6;
		border-bottom: 1px solid #4ba755;
	}
	.cust-reviews-area .nav-tabs a:hover {
		border: 1px solid #ece6e6;
		border-bottom: 1px solid #4ba755;
	}
	.cust-reviews-area .nav-tabs {
		border-bottom: 1px solid transparent;
	}
	.shop-product-area li .adtocart {
		padding: 0 31px;
	}

	.allproduct-info {
		margin-left: 0px;
		margin-top: 35px;
	}
	.shop-tab-wrapper {
		margin-top: 6px;
	}
	.shop-tab-wrapper ul li {
		display: block;
	}
	.tittle_product {
		font-size: 24px;
	}

	.revi_text {
		display: block;
		float: left;
		margin-top: 20px;
	}

	.rat_des {
		margin-top: 30px;
	}

	.revi_text span {
		width: 100%;
	}

	.prod_attributes th {
		width: 140px;
	}

	.categories-area {
		margin-bottom: 0px;
	}

	.blog-header h3 {
		font-size: 40px;
	}

	.recnt_pst_imge {
		width: 83px;
	}

	.recent-title {
		display: block;
		float: left;
		width: 50%;
	}

	.widget-area {
		padding-top: 50px;
	}

	.blog_quote_left img:last-child {
		margin-left: 0px;
		margin-top: 30px;
	}

	.blog-area .blog_quote_left img {
		width: 100%;
	}

	.single_blog_container .comment_cont_wrp {
		display: block;
		float: left;
		margin-top: 10px;
	}

	.service_contact p {
		font-size: 16px;
	}

	.service_contact {
		padding: 30px 25px 15px;
	}

	.download_brochur .files li a {
		font-size: 14px;
		padding: 13px 10px;
	}

	.download_brochur {
		padding: 30px 25px 25px;
	}

	.single_service_cat {
		margin-top: 30px;
	}

	.single_service_left_botom .sing_service_item {
		margin-top: 30px;
	}

	.post_tags {
		width: 100%;
	}
	.post_tags a {
		margin-bottom: 5px;
		display: inline-block;
	}
	.post_share .list-icons {
		float: left;
		margin-top: 20px;
		margin-left: -10px;
	}

	.post_share.footer_socil {
		width: 100%;
	}
	/* Checkout page */

	.checkout_section .col-lg-6:nth-child(2) .custom-title {
		margin-top: 59px;
	}
	.checkout_section .payment_mth {
		padding-top: 52px;
	}
	/* Cart page */
	.shop_cart_bottom .col-sm-12:nth-child(2) {
		margin-bottom: 50px;
	}
	.shop_cart_bottom .col-sm-12:nth-child(1) {
		margin-bottom: 45px;
		margin-top: -8px;
	}
	.shop-page .product-section .col-sm-12 {
		margin-bottom: 0px;
	}
	.shop-page .product-section {
		margin-bottom: 0px;
		padding-top: 112px;
	}
	.info_box p {
		flex-direction: column;
		grid-row-gap: 10px;
		row-gap: 10px;
	}
	.info_box {
		padding: 25px 10px;
	}
	.shop-product-area{
		padding-top: 60px;
	}
	#login-form-wrapper , #register-form-wrapper{
		padding: 20px;
	} 
	.address_wrapper{
		grid-gap: 15px;
		gap: 15px;
	}
	.address_box{
		width: 100%;
	}
	.dashboard .tab_box{
		padding: 40px 20px;
	}
	.info_box_outer p{
		margin-left: 0;
		right: 25px;
	}
	.product_wrapper{
		grid-template-columns: 1fr;
	}
	.pro-checkout{
		padding:0 10px;
	}
	.sidebar{
		padding: 40px 15px;;
	}
	.sidebar_close{
		left: 15px;
	}
	.sidebar .item_box .item_details{
		grid-gap: 10px;
		gap: 10px;
	}
	.sidebar .cart-plus-minus {
		height: 25px;
		border-radius: 3px;
		width: 65px;
	}
	.sidebar .qtybutton {
		font-size: 10px;
	}
	.sidebar .cart-plus-minus-box {
		font-size: 14px;
		height: 25px;
		line-height: 25px;
		width: 20px;
	}
	.shop-tab-wrapper ul{
		display: flex;
   		grid-gap: 20px;
   		gap: 20px;
	}
	.shop-tab-wrapper ul li{
		padding: 0 ;
		margin: 0;
	}
	.shop-tab-wrapper ul li .shop-nav-tabs{
		font-size: 16px;
		padding-right: 0;
		line-height: 1.2;
	}
}

/* Mobile Vertical Layout: 488px 
===============================================================================*/
@media only screen and (min-width: 488px) and (max-width: 767px) {
	.contact_wrp {
		padding: 60px 60px;
	}

	.whychose_wrp {
		padding-left: 40px;
		padding-right: 40px;
	}
	.funfact_wapr .col-md-4.col-sm-12 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.process-section .col-sm-12 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.team_wrp::after {
		background-size: cover;
	}
	.height_custom{
		height: 100%;
	}
	.shop-product-area .category,
	.tags {
		margin-bottom: 5px;
	}
}
/* Mobile Vertical Layout: 488px 
===============================================================================*/
@media only screen and (min-width: 8px) and (max-width: 380px) {
	.navigation .logo img {
		width: auto;
		object-fit: contain;
	}
	.height_custom{
		height: 100%;
	}
}

